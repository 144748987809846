import { Flex, Box, Input, InputRightElement,InputGroup, Button, Text } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import SuccessModal from "../SuccessModal/SuccessModal";
import {transformedVal} from "../../../lib/utilities";
import Select from 'react-select';
import { FiEdit2 } from 'react-icons/fi'
import { bankList } from '../../../api/bankList';
type Options={value:string,label:string}
const BankInfo = ({bankInfo}) => {
  const { accountNumber, bvn, bankCode, accountName } = bankInfo




  const [isEdit,setIsEdit] = useState<boolean>(false);
  const [isSuccess,setIsSuccess ] = useState<boolean>(false);
  const [bankName, setBankName] = useState<Options>({value:'',label:accountName})
  const [acctNum, setAcctNum] = useState<string>(accountNumber)
  const [bvno, setBvn] = useState<string>(transformedVal(bvn))
   const [isBankName, setIsBankName] = useState<boolean>(false)
  const [isAcctNum, setIsAcctNum] = useState<boolean>(false)
  const [isBvn, setIsBvn] = useState<boolean>(false)
const [bankArray, setBankArray] = useState([]);
const options:Options[]=bankArray.map(({bankCode,bankName})=>({value:bankCode,label:bankName}));

const colorStyles={
  control:(styles,{isFocused})=>{
      return {
        ...styles,
        padding:"15px 12px 15px 10px",
        fontSize: '15px',
        lineHeight: '22px',
       height:"3.25rem",
        background:"#FFFFFF",
        borderRadius:"4px",
      };
  },
}

const getBankList = useCallback(async () => {
    const resp = await bankList()
    console.log({ banks: resp })
    if (resp) {
       setBankArray(resp.data)
    }
  }, []);

  useEffect(()=>{
    getBankList();
  },[])
 
  return (
    <>
      <Flex flexDir="column">
        <Text color="#656566" fontWeight="500" fontSize="24px" lineHeight="29px">
          Bank Information
        </Text>
        <Box
          mt="3rem"
          padding="64px 16px"
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          width="99.3%"
          height="20.625rem"
          bg="rgba(7, 5, 41, 0.07)"
          borderRadius="20px">
          <Text
            textAlign="center"
            fontWeight="500"
            fontSize="18px"
            lineHeight="22px"
            color="#1F1F1F">
            {isEdit ? 'Account Details' : 'Add Account'}
          </Text>
          <Flex flexDir="column" maxWidth="64.9rem" height="9.25rem" justifyContent="space-between">
            <Flex width="64.9rem" justifyContent="space-between">
              <Flex width="20.3rem" flexDir="column">
                <Text color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Bank Account
                </Text>

                {isBankName ? (
                  <InputGroup>
                    <Input
                      value={bankName.label}
                      padding="15px 12px 15px 10px"
                      height="3.25rem"
                      background="#FFFFFF"
                      borderRadius="4px"
                    />
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsBankName(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  </InputGroup>
                ) : (
                  <Select
                    value={bankName}
                    styles={colorStyles}
                    onChange={(bankName) => setBankName(bankName)}
                    options={options}
                  />
                )}
              </Flex>

              <Flex width="20.3rem" flexDir="column">
                <Text color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Account Number
                </Text>
                <InputGroup>
                  {!isAcctNum ? (
                    <Input
                      value={acctNum}
                      padding="15px 12px 15px 10px"
                      height="3.25rem"
                      background="#FFFFFF"
                      borderRadius="4px"
                    />
                  ) : (
                    <Input
                      value={acctNum}
                      onChange={(e) => setAcctNum(e.target.value)}
                      padding="15px 12px 15px 10px"
                      height="3.25rem"
                      background="#FFFFFF"
                      borderRadius="4px"
                    />
                  )}
                  {!isAcctNum ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsAcctNum(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </Flex>

              <Flex width="20.3rem" flexDir="column">
                <Text color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Bvn
                </Text>
                <InputGroup>
                  {!isBvn ? (
                    <Input
                      value={bvn}
                      padding="15px 12px 15px 10px"
                      height="3.25rem"
                      background="#FFFFFF"
                      borderRadius="4px"
                    />
                  ) : (
                    <Input
                      value={bvn}
                      onChange={(e) => setBvn(e.target.value)}
                      padding="15px 12px 15px 10px"
                      height="3.25rem"
                      background="#FFFFFF"
                      borderRadius="4px"
                    />
                  )}
                  {!isBvn ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsBvn(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </Flex>
            </Flex>
            <Flex alignSelf="flex-end">
              {isEdit ? (
                <Button
                  lineHeight="22px"
                  fontWeight="500"
                  fontSize="14px"
                  color="#595956"
                  padding="8px 24px"
                  flexShrink="0"
                  width="5.125rem"
                  height="2.375rem"
                  background="rgba(6, 0, 137, 0.15)"
                  borderRadius="8px "
                  isDisabled={true}>
                  Done
                </Button>
              ) : (
                <SuccessModal
                  bankName={bankName}
                  bvn={bvn}
                  acctNum={acctNum}
                  setIsEdit={setIsEdit}
                />
              )}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default BankInfo
