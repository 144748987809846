import React, { useState, useCallback, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../Style/signUp.css';
import { Flex, Box, Button, Image, Text, Input, useMediaQuery } from '@chakra-ui/react'
import { useAuth } from '../lib/hooks/useAuth'
import { signInApi } from '../api/login'
import Logo from '../Asset/Onbarding/SENDRAILS.png'
import { SideBarOnboard } from '../Component/SideBarOnbard/SideBarOnboard'
import { FormHead } from '../Component/FormHead/FormHead'
import { useAxiosPrivate } from '../lib/hooks/useAxiosPrivate'

function SignIn() {
  const signin=true;
  const { auth,setAuth } = useAuth()
 const [isSmallerThan900] = useMediaQuery('(max-width: 900px)')
 const [isSmallerThan740] = useMediaQuery('(max-width: 740px)')
 const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
 const [isSmallerThan530] = useMediaQuery('(max-width: 530px)')
  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const axiosPrivate = useAxiosPrivate()
console.log({ auth: auth })
   const login = useCallback(async () => {
     setIsLoading(true);
   
     const resp = await signInApi({email,password})
    
     setIsLoading(false)
     if (resp) {
       const accessToken = resp?.data?.token;  
  const xAccess = resp?.data['x_access'];

  localStorage.setItem ("token", accessToken)
  localStorage.setItem ("access", xAccess)
  navigate('/dashboard')
     }
     
   }, [email, password])

 
const reset=()=>{
  setEmail('');
  setPassword('');
}
  return (
    <Flex width="100%" height="76.5rem">
      <SideBarOnboard
        isSmallerThan530={isSmallerThan530}
        isSmallerThan1024={isSmallerThan1024}
        isSmallerThan900={isSmallerThan900}
        isSmallerThan740={isSmallerThan740}
        login={signin}
      />
      <Flex
        flexDir="column"
        width="61.8%"
        height="100%"
        pt={isSmallerThan740 ? '2rem' : isSmallerThan1024 ? '3rem' : '5rem'}
        px={isSmallerThan740 ? '2rem' : isSmallerThan1024 ? '3rem' : '6.4375rem'}>
        <FormHead login={signin} />
        <Flex width="30rem" alignSelf="center" mt="20px" flexDir="column">
          <Text
            color="text.50"
            flexShrink="0"
            fontWeight="500"
            fontSize={isSmallerThan740 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
            lineHeight={isSmallerThan740 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
            Email
          </Text>
          <Input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mt="0.5rem"
            width="100%"
            height="2.6rem"
            background="rgba(6, 0, 137, 0.05)"
            border="1px solid #ABA7A7"
            borderRadius="4px"
            fontWeight="500"
            fontSize="16px"
            lineHeight="22px"
            color="#1F1F1F"
            focusBorderColor="primary.main"
          />
        </Flex>
        <Flex width="30rem" alignSelf="center" mt="20px" flexDir="column">
          <Text
            color="text.50"
            flexShrink="0"
            fontWeight="500"
            fontSize={isSmallerThan740 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
            lineHeight={isSmallerThan740 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
            Password
          </Text>
          <Input
            mt="0.5rem"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            width="100%"
            height="2.6rem"
            background="rgba(6, 0, 137, 0.05)"
            border="1px solid #ABA7A7"
            borderRadius="4px"
            fontWeight="500"
            fontSize="16px"
            lineHeight="22px"
            color="#1F1F1F"
            focusBorderColor="primary.main"
          />
        </Flex>
        <Button
          alignSelf="center"
          mt="60px"
          padding="16px 32px"
          width="30rem"
          height="2.6rem"
          isLoading={isLoading?true:false}
          loadingText="Loading"
          background="primary.main"
          variant={isLoading?'outline':"filled"}
    spinnerPlacement='start'
          borderRadius="4px"
          fontWeight="500"
          onClick={() => login()}
          fontSize="18px"
          lineHeight="22px"
          color="#fff"
          textAlign="center"
          isDisabled={!(email && password && email.includes('@'))}
          _hover={{
            background: '#16134f'
          }}>
          Sign in
        </Button>
        <Flex alignSelf="center" mt="2rem" width="30rem" justifyContent="space-between">
          <Text cursor="pointer" fontSize="18px" fontWeight="700" color="primary.main">
            <Link to="/forgot-password" className="Link1">
              Forgot Password ?
            </Link>
          </Text>
          <Flex>
            <Text fontSize="18px">Don’t have an account?</Text>

            <Text cursor="pointer" fontSize="18px"  ml="3px" color="primary.main">
              {' '}
              <Link to="/sign-up" className="Link1">
                Sign up
              </Link>
            </Text>
          </Flex>
        </Flex>
        
      </Flex>
      
    </Flex>
  )
}

export default SignIn
