
import React from 'react'
import {
  Flex,
  Box,
  Text,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormLabel,
  Image,
  PinInputField,
  PinInput,
  HStack,
  Button
} from '@chakra-ui/react'


export const NewPin=()=>{
    return (
      <>
        <Flex
          flexDir="column"
          width="412px"
          height="301px"
          padding="40px 80px"
          borderRadius="20px"
          justifyContent="space-between"
          background="rgba(7, 5, 41, 0.07)">
          <Flex flexDir="column" width="252px" height="135px" justifyContent="space-between">
            <Text fontWeight="500" fontSize="18px" lineHeight="22px" color="#1F1F1F">
              Change Pin
            </Text>
            <Flex flexDir="column" width="252px" height="89px" justifyContent="space-between">
              <Text fontWeight="600" fontSize="16px" lineHeight="22px" color="text.100">
                Enter new pin
              </Text>
              <HStack spacing="24px">
                <PinInput focusBorderColor="black" placeholder="" size="lg">
                  <PinInputField background="#fff" />
                  <PinInputField background="#fff" />
                  <PinInputField background="#fff" />
                  <PinInputField background="#fff" />
                </PinInput>
              </HStack>
            </Flex>
          </Flex>
          <Button
            alignSelf="flex-end"
            padding="8px 24px"
            borderRadius="4px"
            width="101px"
            height="38px"
            background="primary.main"
            fontWeight="500"
            fontSize="14px"
            color="text.50"
            lineHeight="22px">
            Next
          </Button>
        </Flex>
      </>
    )
}