import { Box, Grid, Image, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

interface Types {
  menu_button?: JSX.Element
  width?: string
  data?: { text: string; onclick: () => void; disabled?: boolean }[]
  variant?: string // menu-dropdown, select-dropdown-1, select-dropdown-2
}

// I want to use this dropdown for all select dropdowns and menu dropdowns
const CustomDropDown = ({ menu_button, data, variant, width }: Types): JSX.Element => {
  switch (variant) {
    case 'menu-dropdown':
      return <MenuDropDown data={data} width={width} />
    default:
      return <MenuDropDown data={data} width={width} />
  }
}

export default CustomDropDown

CustomDropDown.defaultProps = {
  button: null,
  variant: 'menu-dropdown'
}

// For dotted menu dropdowns
const MenuDropDown = ({
  data,
  width
}: {
  data: { text: string; onclick: () => void; disabled?: boolean }[]
  width?: string
}) => {
  return (
    <MenuList
      minW="0"
      w={width ? width : '154px'}
      zIndex="99"
      color="#AAAAAA"
      borderRadius="10px"
      padding="0px"
      fontSize="14px"
      overflow="hidden">
      {data?.map((i) => (
        <MenuItem
          fontWeight="500"
          padding="16px"
          color={i?.disabled ? '#E1DADA' : 'inherit'}
          _hover={{
            background: i?.disabled ? 'inherit' : '#F3EFEF',
            color: i?.disabled ? '#E1DADA' : '#595956'
          }}
          onClick={!i?.disabled && i.onclick}>
          {i?.text}
        </MenuItem>
      ))}
    </MenuList>
  )
}
