import React, { useState, useRef } from "react";
import { 
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
    Text,
    Box,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Select,
    Grid,
    Button
} from "@chakra-ui/react";
import { 
    CalendarIcon, 
    CheckIcon, 
    SmallAddIcon, 
    SmallCloseIcon, 
    TimeIcon }
     from '@chakra-ui/icons';
import ModalTopLine from "../../../shared/ModalTopLine";
import { ImCancelCircle } from "react-icons/im";
import { MdArrowDropDown } from 'react-icons/md';

interface Props{
    isOpen?: boolean,
    onClose?: ()=> void,
    
}

const EditParcel = ({ isOpen, onClose }: Props)=>{
    const [files, setFiles] = useState([]);
    const fileRef = useRef<HTMLInputElement>(null);
    return(
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalBody color="#595956" p={6}>
                        <Flex 
                            alignItems="center" 
                            justifyContent="space-between" 
                            color="#656566" 
                            p={5}>
                                <Text>Parcel 1</Text>
                                <Text cursor="pointer" onClick={()=>onClose()}><ImCancelCircle /></Text>
                        </Flex>
                        <ModalTopLine />
                        <Box display="flex" flexDirection="column">
                            <FormControl my={3}>
                            <FormLabel mb="8px">Parcel content</FormLabel>
                                <Input
                                    type='text'
                                    placeholder="macbook pro"
                                    background="#fff"
                                    color="#ABA7A7"
                                />
                            </FormControl>
                            <Box display="flex" my={3}>
                                <FormControl>
                                    <FormLabel mb="8px">No of contents</FormLabel>
                                    <Input
                                        type='phone'
                                        placeholder='0'
                                        background="#fff"
                                        width="120px"
                                        color="#ABA7A7"
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel mb="8px">Select an option</FormLabel>
                                    <Select
                                        border="none"
                                        icon={<MdArrowDropDown />}
                                        placeholder="Category"
                                        width="100%"
                                        backgroundColor="#fff"
                                    >
                                        <option>Computer Accessories</option>
                                        <option>Clothings</option>
                                        <option>Phones</option>
                                        <option>Documents</option>
                                        <option>Electronics</option>
                                        <option>Others</option>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Flex
                                gridColumn="1/3"
                                columnGap="8px"
                                width="100%"
                                background="#FFFFFF"
                                height="97px"
                                borderRadius="10px"
                                padding={{ sm: "", md: "16px" }}
                                border="1px"
                                borderColor="rgba(6, 0, 137, 0.10)"
                                my={7}
                            >
                                {
                                    files?.map(e => (
                                        <Grid
                                            key={e}
                                            position="relative"
                                            placeItems="center"
                                            background={e ? `url(${URL.createObjectURL(e)})` : "#ABA7A7"}
                                            backgroundPosition="center"
                                            backgroundSize="cover"
                                            backgroundRepeat="no-repeat"
                                            w="max-content"
                                            borderRadius="10px"
                                            h="100%"
                                            style={{
                                                aspectRatio: "9/8"
                                            }}
                                        >
                                            <SmallCloseIcon
                                                boxSize={23}
                                                padding="2px"
                                                borderRadius="50%"
                                                border="1px dotted red"
                                                color="red"
                                                background="rgba(0,0,0,0.2)"
                                                position="absolute"
                                                top="-10px"
                                                right="0"
                                                cursor="pointer"

                                                onClick={() => {
                                                    let newFile = files.filter(i => i !== e)
                                                    setFiles(newFile)
                                                }}
                                            />
                                        </Grid>
                                    ))
                                }
                                {files.length != 5 &&
                                    <Flex h="100%" alignItems="center" columnGap="8px">
                                        <Grid
                                            placeItems="center"
                                            border="1px dashed #ABA7A7"
                                            w="max-content"
                                            borderRadius="10px"
                                            cursor="pointer"
                                            h="100%"
                                            style={{
                                                aspectRatio: "9/8"
                                            }}
                                            onClick={() => { fileRef.current.click() }}
                                        >
                                            <SmallAddIcon boxSize={25} />
                                        </Grid>
                                        <Text color="#ABA7A7">
                                            Click to browse or drop an image file
                                        </Text>
                                    </Flex>
                                }
                                <Input
                                    type="file"
                                    ref={fileRef}
                                    accept="image/*"
                                    display="none"
                                    onChange={(e) => {
                                        setFiles([...files, e.target.files[0]]);
                                        e.target.value = "";
                                    }}
                                />
                            </Flex>
                            <Box display="flex" alignItems="center" justifyContent="center" my={5}>
                                <Flex width="350px" justifyContent="space-between">
                                        <Button bg="#fff" color="#334" border="1px" borderColor="#ABA7A7" onClick={()=>onClose()}>Back</Button>
                                        <Button bg="rgba(7, 5, 41, 0.90)" color="#fff">Save Changes</Button>
                                </Flex>
                            </Box>
                            
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
export default EditParcel;