import React from 'react'
import '../../Style/signUp.css'
import {
  Flex,
  Box,
  Image,
  Button,
  Text,
  FormControl,
  Heading,
  Input,
  Select,
  useMediaQuery,
  Highlight
} from '@chakra-ui/react'
import inv from '../../Asset/signUp/inv.png'
import sendrail from '../../Asset/signUp/SENDRAILS.png'
import Lshape from '../../Asset/signUp/Vector 1.png'
type Props = {
  isSmallerThan530?: boolean,
  isSmallerThan1024?: boolean,
  isSmallerThan900?: boolean,
   isSmallerThan740?: boolean,
  login?: boolean,
  signup?: boolean,
}
export const SideBarOnboard = ({
  isSmallerThan530,
  isSmallerThan1024,
  isSmallerThan900,
  isSmallerThan740,
  login,signup
}:Props) => {
  
  return (
    <Flex
      display={isSmallerThan530 ? 'none' : 'flex'}
      width="38.2%"
      height="100%"
      flexDirection="column"
      className={login ? 'Signin' : signup ? 'SideBar' : 'Verify'}>
      <Flex
        mt={isSmallerThan900 ? '40px' : isSmallerThan1024 ? '60px' : '80px'}
        ml={isSmallerThan740?"20px":isSmallerThan900 ? '40px' : isSmallerThan1024 ? '20px' : '25px'}>
        <Image
          alignSelf="baseline"
          src={sendrail}
          alt=""
          width={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '20px' : '30px'}
          height={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '15px' : '22px'}
          objectFit="cover"
        />
        <Text
          ml={isSmallerThan900 ? '3px' : isSmallerThan1024 ? '3px' : '5px'}
          alignSelf="flex-start"
          fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '20px' : '24px'}
          fontWeight="700"
          lineHeight={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '15px' : '22px'}
          color="primary.100">
          Sendrail
        </Text>
      </Flex>
      <Flex
        alignSelf={isSmallerThan900 ? '' : 'center'}
        flexDir="column"
        ml={isSmallerThan900 ? '20px' : isSmallerThan900 ? '40px' : ''}
        height={isSmallerThan900 ? '13rem' : isSmallerThan1024 ? '15rem' : '18rem'}
        justifyContent="space-between"
        mt={isSmallerThan900 ? '12rem' : isSmallerThan1024 ? '14rem' : '15.3125rem'}
        width="25.5rem">
        <Flex>
          <Image
            alignSelf="flex-start"
            src={inv}
            alt=""
            width={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '20px' : '28px'}
            height={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '20px' : '30px'}
            objectFit="contain"
          />
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="space-between"
          height={isSmallerThan900 ? '4rem' : isSmallerThan1024 ? '5rem' : '6.375rem'}>
          <Text
            flexShrink="0"
            fontSize={
              isSmallerThan530
                ? '8px'
                : isSmallerThan900
                ? '12px'
                : isSmallerThan1024
                ? '16px'
                : '20px'
            }
            fontWeight="600"
            lineHeight={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '18px' : '22px'}
            color="primary.100">
            Want to improve your logistic processes{' '}
          </Text>
          <Text
            flexShrink="0"
            fontSize={
              isSmallerThan530
                ? '8px'
                : isSmallerThan900
                ? '12px'
                : isSmallerThan1024
                ? '16px'
                : '20px'
            }
            fontWeight="600"
            lineHeight={isSmallerThan900 ? '14px' : isSmallerThan1024 ? '18px' : '22px'}
            color="primary.100">
            and stay ahead of the competition?
          </Text>
          <Text
            flexShrink="0"
            fontSize={
              isSmallerThan530
                ? '8px'
                : isSmallerThan900
                ? '12px'
                : isSmallerThan1024
                ? '16px'
                : '20px'
            }
            fontWeight="600"
            lineHeight={isSmallerThan900 ? '14px' : isSmallerThan1024 ? '18px' : '22px'}
            color="primary.100">
            Sendrail has the solution you need.
          </Text>
        </Flex>
        <Flex alignSelf={isSmallerThan900 ? '' : 'flex-end'} ml={isSmallerThan900 ? '200px' : ''}>
          <Image
            src={Lshape}
            alt=""
            width={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '25px' : '33px'}
            height={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '25px' : '33px'}
            objectFit="cover"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}