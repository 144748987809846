import React, { useState,useCallback } from "react";
import '../Style/signUp.css'
import {
  Flex,
  Box,
  Image,
 useMediaQuery,
  Text,
  
  Input,
 
  Button,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'
import Logo from "../Asset/Onbarding/SENDRAILS.png";
import {forgotPass} from "../api/forgot_password";
import { SideBarOnboard } from '../Component/SideBarOnbard/SideBarOnboard'
function ForgotPassword() {
  const [email, setEmail] = useState("");
    const signin = true;
    const [isSmallerThan900] = useMediaQuery('(max-width: 900px)')
    const [isSmallerThan740] = useMediaQuery('(max-width: 740px)')
    const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
    const [isSmallerThan530] = useMediaQuery('(max-width: 530px)')
const navigate = useNavigate()
 const newPassword = useCallback(async () => {
   try {
     const resp = await forgotPass(email)
     console.log(resp);
    //  if(resp?.data?.success){
    //    navigate('/reset-Password', { state: { email: resp.data.data?.email } })
    //  }
   } catch (e) {
     console.log(e)
   }
 },[email,navigate])
  return (
    <Flex width="100%" height="76.5rem">
      <SideBarOnboard
        isSmallerThan530={isSmallerThan530}
        isSmallerThan1024={isSmallerThan1024}
        isSmallerThan900={isSmallerThan900}
        isSmallerThan740={isSmallerThan740}
        login={signin}
      />

      <Flex
        flexDir="column"
        width="61.8%"
        alignItems="center"
        height="100%"
        pt={isSmallerThan740 ? '2rem' : isSmallerThan1024 ? '3rem' : '5rem'}
        px={isSmallerThan740 ? '2rem' : isSmallerThan1024 ? '3rem' : '6.4375rem'}>
        <Flex
          flexDir="column"
          width="31rem"
          height="23rem"
          alignItems="flex-start"
          justifyContent="space-between">
          <Flex
            width="100%"
            height="11rem"
            flexDir="column"
            alignItems="flex-start"
            justifyContent="space-between">
            <Flex
              flexDir="column"
              height="103px"
              alignSelf="center"
              alignItems="center"
              justifyContent="space-between">
              <Flex
                width="11.5rem"
                height="3.56rem"
                flexDir="column"
                justifyContent="space-between"
                alignItems="center">
                <Image src={Logo} alt="Sendrail" width="30px" height="22px" objectFit="cover" />
                <Text
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="22px"
                  color="#000000"
                  flexShrink="0">
                  Forgot Password
                </Text>
              </Flex>
              <Flex mt="20px" width="25rem" height="1.375rem" flexDir="column" alignItems="center">
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="22px"
                  color="#000000"
                  flexShrink="0">
                  Enter Your Email A Verification Code Will Be Sent To You
                </Text>
              </Flex>
            </Flex>
            <Flex alignSelf="center" mt="20px">
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                type="email"
                mt="31px"
                width="30rem"
                height="42px"
                background="#fff"
                border="1px solid #ABA7A7"
                borderRadius="4px"
                fontWeight="500"
                fontSize="16px"
                lineHeight="22px"
                color="#1F1F1F"
                focusBorderColor="primary.main"
              />
            </Flex>
            <Button
              mt="70px"
              padding="16px 32px"
              width="31rem"
              height="48px"
              background="primary.main"
              borderRadius="4px"
              fontWeight="500"
              fontSize="18px"
              lineHeight="22px"
              color="#fff"
              textAlign="center"
              isDisabled={!email}
              onClick={newPassword}
              _hover={{
                background: '#16134f'
              }}>
              Next
            </Button>
            
          </Flex>
          <Flex
            alignSelf="flex-start"
            
            justifyContent="space-between"
            width={isSmallerThan740 ? '10rem' : isSmallerThan1024 ? '12rem' : '15.3125rem'}>
            <Text
              color="text.50"
              flexShrink="0"
              fontWeight="500"
              fontSize={isSmallerThan740 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
              lineHeight={isSmallerThan740 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
              Remember password?
            </Text>
            <Button
              variant="link"
              fontWeight="500"
              fontSize={isSmallerThan740 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
              lineHeight={isSmallerThan740 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
              color="primary.main"
              onClick={() => navigate('/login')}
              _hover={{
                textDecoration: 'none'
              }}>
              Sign in
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ForgotPassword;
