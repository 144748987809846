import {
  Flex,
  Box,
  Text,
  Input,
  Image,
Select,Button
} from '@chakra-ui/react'
import camera from '../../../Asset/Rider/camera.png'
import { useAxiosPrivate } from '../../../lib/hooks/useAxiosPrivate'
import React,{useState,useReducer,useCallback} from 'react'

import './Courier.css'

import addCourierReducer from '../../../reducers/addCourierReducer'
import Bus from '../../../Asset/Rider/bus.png'
import link from '../../../Asset/Rider/link.png'
import Bicycle from '../../../Asset/Rider/bicycle.png'
import Car from '../../../Asset/Rider/car.png'
import Bike from '../../../Asset/Rider/bike.png'
import { AutoLocation } from '../AutoLocation'

const initialState = {
  profilePicture: '',
  fullName: '',
  phoneNumber: '',
  dateOfBirth: '',
  residentialAddress: '',
  stateVal: '',
  accountNumber: '',
  userName: '',
  industry: '',
  email: '',
  gender: '',
  city: '',
  country: '',
  bankAccount: '',
  accountPassword: '',
}

const AddCourier = ({ setAddCourier,setTable }) => {
  // const [isDocs,setIsDocs]=useState<boolean>(false);
  // const [isGuarantor, setIsGuarantor] = useState<boolean>(false);
  const [isDate, setIsDate] = useState<boolean>(false)
   const [address, setAddress] = useState<boolean>(false)
  const [state, setState] = useState<any>(initialState)
  const axiosPrivate = useAxiosPrivate()
  const [isLoading, setIsLoading] = useState(false)
  console.log({ stateAdd: state })
  const getPlaces = (value) => value
  const {
    profilePicture,
    fullName,
    phoneNumber,
    dateOfBirth,
    residentialAddress,
    stateVal,
    accountNumber,
    userName,
    industry,
    email,
    gender,
    city,
    country,
    bankAccount,
    accountPassword
  } = state

  const handleChange = (e) => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  const reset = () => {
    setState({ ...state, ...initialState })
  }

  const createCourier = useCallback(async () => {
    setIsLoading(true)
    const resp = await axiosPrivate.post('/courier/add/dependent', {
      profilePicture,
      fullName,
      phoneNumber,
      dateOfBirth,
      residentialAddress,
      state: stateVal,
      accountNumber,
      userName,
      industry,
      email,
      gender,
      city,
      country,
      bankAccount,
      accountPassword
    })
    setIsLoading(false)
    console.log(resp)
  }, [
    profilePicture,
    accountNumber,
    userName,
    industry,
    stateVal,
    email,
    gender,
    city,
    country,
    bankAccount,
    accountPassword,
    fullName,
    phoneNumber,
    dateOfBirth,
    residentialAddress
  ])

  // useEffect(() => {
  //   localStorage.setItem('dataKey', JSON.stringify(state))
  // }, [state])

  //  const handleFileUpload = async (e) => {
  //    const file = e.target.files[0]
  //    const base64 = await convertToBase64(file)

  //    dispatch({ type: 'profilepicture', payload: base64 })
  //  }

  return (
    <Box
      mt="3rem"
      maxWidth="58.9375rem"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      padding="3rem 5.5rem"
      boxSizing="border-box"
      background="rgba(7, 5, 41, 0.07)"
      borderRadius="20px">
      {/* inner flex */}
      <Flex flexDir="column" justifyContent="space-between" width="47.9375rem" height="75.4375rem">
        {/* start headerg */}
        <Flex flexDir="column">
          <Text color="#656566" fontWeight="500" fontSize="24px" lineHeight="29px">
            Personal Information
          </Text>
          <Box className="Wrapper">
            <Input
              type="file"
              className="my_upload"
              value={profilePicture}
              name="profilePicture"
              onChange={handleChange}
            />
            <Image
              src={camera}
              alt="camera"
              position="absolute"
              top="53%"
              right="0"
              width="32px"
              height="32px"
              objectFit="cover"
            />
          </Box>
        </Flex>
        {/* end header */}

        {/* input flex */}
        <Flex flexDir="column" width="47.9375rem" height="54rem" justifyContent="space-between">
          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Name
              </Text>
              <Input
                value={fullName}
                name="fullName"
                placeholder="Enter full name"
                _placeholder={{
                  color: 'text.100'
                }}
                mt="4px"
                onChange={handleChange}
                padding="0px 12px "
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                focusBorderColor="primary.main"
              />
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Industry
              </Text>
              <Select
                placeholder="Select an industry"
                mt="4px"
                value={industry}
                name="industry"
                onChange={handleChange}
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize={{ base: '10px', md: '12px', lg: '18px' }}
                lineHeight={{ base: '10px', md: '15px', lg: '22px' }}
                bg="#fff"
                color="#000000"
                focusBorderColor="primary.main">
                <option value="individual">Individual</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Flex>
          </Flex>
          {/* end 1st  */}
          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Phone Number
              </Text>
              <Input
                mt="4px"
                padding="0px 12px"
                value={phoneNumber}
                name="phoneNumber"
                placeholder="Enter phone number "
                onChange={handleChange}
                _placeholder={{
                  color: 'text.100'
                }}
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
              />
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Email Address
              </Text>
              <Input
                mt="4px"
                placeholder="Enter email"
                value={email}
                name="email"
                onChange={handleChange}
                _placeholder={{
                  color: 'text.100'
                }}
                padding="0px 12px "
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
              />
            </Flex>
          </Flex>
          {/* end 2nd */}
          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Date of birth
              </Text>
              <Input
                type="date"
                mt="4px"
                value={dateOfBirth}
                name="dateOfBirth"
                onChange={handleChange}
                placeholder="Enter date of birth"
                _placeholder={{
                  color: 'text.100'
                }}
                padding="0px 12px"
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
              />
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Gender
              </Text>
              <Select
                placeholder="Select an industry"
                value={gender}
                name="gender"
                onChange={handleChange}
                mt="4px"
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize={{ base: '10px', md: '12px', lg: '18px' }}
                lineHeight={{ base: '10px', md: '15px', lg: '22px' }}
                bg="#fff"
                color="#000000"
                focusBorderColor="primary.main">
                <option value="M">Male</option>
                <option value="F">Female</option>
              </Select>
            </Flex>
          </Flex>
          {/* end 3rd */}

          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Residential Address
              </Text>
              <AutoLocation
                lg="3.5rem"
                padding="0px 12px"
                bRadius="4px"
                lineLg="22px"
                color="text.100"
                fntW="500"
                fntLg="18px"
                mtLg="4px"
                bg="#fff"
                plHolderText="Enter address"
                focusBorder="primary.main"
                getPlaces={getPlaces}
              />
              {/* <Input
                placeholder="Enter address"
                mt="4px"
                padding="0px 12px "
                value={residentialAddress}
                name="residentialAddress"
                onChange={handleChange}
                _placeholder={{
                  color: 'text.100'
                }}
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
              /> */}
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                City
              </Text>
              <Input
                value={city}
                name="city"
                onChange={handleChange}
                mt="4px"
                placeholder="Enter a city"
                padding="0px 12px "
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
          </Flex>
          {/* end 4th */}

          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                State
              </Text>
              <Input
                placeholder="Enter a state"
                mt="4px"
                value={stateVal}
                name="stateVal"
                onChange={handleChange}
                padding="0px 12px "
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Country
              </Text>
              <Input
                placeholder="Enter a country"
                mt="4px"
                padding="0px 12px "
                value={country}
                name="country"
                onChange={handleChange}
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
          </Flex>

          {/* end 5th */}

          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Account number
              </Text>
              <Input
                placeholder="Enter an account number"
                mt="4px"
                value={accountNumber}
                name="accountNumber"
                onChange={handleChange}
                padding="0px 12px "
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Bank account
              </Text>
              <Input
                mt="4px"
                value={bankAccount}
                placeholder="Enter a bank"
                padding="0px 12px "
                width="100%"
                name="bankAccount"
                onChange={handleChange}
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
          </Flex>
          {/* end 6th */}

          <Flex width="100%" justifyContent="space-between">
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Username
              </Text>
              <Input
                placeholder="Enter a username"
                mt="4px"
                padding="0px 12px "
                value={userName}
                name="userName"
                onChange={handleChange}
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                focusBorderColor="primary.main"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
            <Flex flexDir="column" width="20.625rem" height="5.125rem">
              <Text color="text.50" fontWeight="450" fontSize="18px" lineHeight="22px">
                Account password
              </Text>
              <Input
                placeholder="**********"
                mt="4px"
                value={accountPassword}
                name="accountPassword"
                onChange={handleChange}
                padding="0px 12px "
                width="100%"
                height="3.5rem"
                background="#fff"
                borderRadius="4px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color="text.100"
                _placeholder={{
                  color: 'text.100'
                }}
                focusBorderColor="primary.main"
              />
            </Flex>
          </Flex>
        </Flex>
        {/* end input flex */}

        <Flex
          width="16.75rem"
          height="5.375rem"
          flexDirection="column"
          justifyContent="space-between">
          <Text color="text.50" fontWeight="500" fontSize="18px" lineHeight="22px">
            Transportation type
          </Text>
          <Flex width="100%" justifyContent="space-between">
            <Image src={Bike} alt="Bike" width="32px" height="18.67px" />
            <Image src={Bus} alt="Bus" width="26.67px" height="25.34px" />
            <Image src={Car} alt="Car" width="28px" height="20px" />
            <Image src={Bicycle} alt="Bicycle" width="26.67px" height="24px" />
          </Flex>
        </Flex>
      </Flex>
      {/* end inner flex */}

      <Flex mt="3rem" justifyContent="space-between">
        <Flex flexDir="column" width="11.0625rem" height="9.875rem" justifyContent="space-between">
          <Button
            fontSize="calc(0.625rem + 0.41667vw)"
            width="8.625rem"
            height="3.375rem"
            color="#fff"
            isLoading={isLoading ? true : false}
            loadingText="Loading"
            spinnerPlacement="start"
            variant={isLoading ? 'outline' : 'filled'}
            onClick={() => {
              createCourier()
              reset()
              setAddCourier(false)
              setTable(true)
            }}
            background="primary.main"
            borderRadius="8px"
            padding="1rem 2rem"
            textAlign="center"
            _hover={{
              background: 'rgba(6, 0, 137, 0.15)'
            }}>
            Save rider
          </Button>
          <Button
            fontSize="calc(0.75rem + 0.41667vw)"
            border="1px solid #ABA7A7"
            width="11.0625rem"
            height="3.5rem"
            color="#000"
            background="#fff"
            borderRadius="8px"
            padding="1rem 2rem"
            _hover={{
              background: '#fff'
            }}>
            <Flex>
              <Image src={link} alt="Link" width="24px" height="24px" objectFit="cover" />
              <Text ml="4px"> Copy link</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {/* <Button
            alignSelf="flex-end"
            width="2.75rem"
            height="2.75rem"
            color="#fff"
            background="primary.main"
            borderRadius="4px"
            padding="0.625rem"
            onClick={() => setIsDocs(true)}
            _hover={{
              background: 'primary.main'
            }}>
            {'>'}
          </Button> */}
    </Box>
  )
}

export default AddCourier;
