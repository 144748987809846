import React,{useState} from 'react';
import {
  Flex,
  Box,
  Text,
 
  Image,
 
} from '@chakra-ui/react'
import Arrowright from '../../../Asset/Settings/arrowright.png'
type ICustomData = {
  flexWidth?: string
  flexHeight?: string
  wrapperHeight?: string
  lineH?: string
  size?: string
  weight?: string
  color?: string
  trans?: boolean
  login?: boolean
  Text1?: string
  Text2?: string
  security?: boolean
  setIsTransaction?: React.Dispatch<React.SetStateAction<boolean>>
  setSecurity?: React.Dispatch<React.SetStateAction<boolean>>
  setIsLogin?: React.Dispatch<React.SetStateAction<boolean>>
  setChangePin?: React.Dispatch<React.SetStateAction<boolean>>
  setForgotPin?: React.Dispatch<React.SetStateAction<boolean>>
  setChangePass?: React.Dispatch<React.SetStateAction<boolean>>
  setForgotPass?: React.Dispatch<React.SetStateAction<boolean>>
  setIsTrans?: React.Dispatch<React.SetStateAction<boolean>>
}
export const CustomBox = ({
  flexWidth,
  login,
  lineH,
  size,
  weight,
  flexHeight,
  wrapperHeight,
  color,
  trans,
  Text1,
  Text2,
  setIsTransaction,
  security,
  setSecurity,
  setIsLogin,
  setChangePin,
  setForgotPin,
  setChangePass,
  setForgotPass,
  setIsTrans
}: ICustomData) => {
  const texts = [Text1, Text2]
  const handleTest1 = () => {
    setIsLogin(true)
    setSecurity(false)
  }
  const handleTest2 = () => {
    setIsLogin(false)
    setSecurity(false)
  }

  const handleChangePin = () => {
   setChangePin(true)
    setIsTrans(false)
  }
  const handleForgotPin = () => {
 console.log('you clicked')
    setChangePin(false)
    setIsTrans(false)
  }

  const handleChangePass = () => {
   
    
    setChangePass(true)
    setForgotPass(false)
  }
  const handleForgotPass = () => {
    
    setChangePass(false)
    setForgotPass(true)
  }
  return (
    <>
      {security ? (
        <Flex
          flexDir="column"
          width={flexWidth}
          height={wrapperHeight}
          justifyContent="space-between">
          {texts?.map((text, idx) => (
            <Flex key={idx} width={flexWidth} height={flexHeight} position="relative">
              <Box
                background="#fff"
                border="none"
                width="100%"
                height="100%"
                borderRadius="10px"
                fontWeight={weight || 500}
                fontSize={size || '18px'}
                lineHeight={lineH || '22px'}
                color={color}
                padding="24px 32px">
                {text}
              </Box>
              <Image
                src={Arrowright}
                alt=""
                width="24px"
                height="24px"
                objectFit="cover"
                position="absolute"
                top="40%"
                left="90%"
                onClick={idx == 0 ? handleTest1 : handleTest2}
                cursor="pointer"
              />
            </Flex>
          ))}
        </Flex>
      ) : login || trans===undefined ? (
        <Flex
          flexDir="column"
          width={flexWidth}
          height={wrapperHeight}
          justifyContent="space-between">
          {texts?.map((text, idx) => (
            <Flex key={idx} width={flexWidth} height={flexHeight} position="relative">
              <Box
                background="#fff"
                border="none"
                width="100%"
                height="100%"
                borderRadius="10px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color={color}
                padding="24px 32px">
                {text}
              </Box>
              <Image
                src={Arrowright}
                alt=""
                width="24px"
                height="24px"
                objectFit="cover"
                position="absolute"
                top="40%"
                left="90%"
                onClick={idx == 0 ? handleChangePass : handleForgotPass}
                cursor="pointer"
              />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          width={flexWidth}
          height={wrapperHeight}
          justifyContent="space-between">
          {texts?.map((text, idx) => (
            <Flex key={idx} width={flexWidth} height={flexHeight} position="relative">
              <Box
                background="#fff"
                border="none"
                width="100%"
                height="100%"
                borderRadius="10px"
                fontWeight="500"
                fontSize="18px"
                lineHeight="22px"
                color={color}
                padding="24px 32px">
                {text}
              </Box>
              <Image
                src={Arrowright}
                alt=""
                width="24px"
                height="24px"
                objectFit="cover"
                position="absolute"
                top="40%"
                left="90%"
                onClick={idx == 0 ? handleChangePin : handleForgotPin}
                cursor="pointer"
              />
            </Flex>
          ))}
        </Flex>
      )}
    </>
  )
}