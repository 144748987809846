import React, { useState } from "react";
import { Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from "@chakra-ui/react";
import { DrawerContents } from "../../ManageRider/DrawerContents";
import phn from "../../../../Asset/Rider/phn.png";
import msg from "../../../../Asset/Rider/msg.png";
import { DrawerHead } from "../../ManageRider/DrawerHead";


// interface Props{
//     isOpen: boolean,
//     onClose: ()=> void,


// }
const ShipmentOrder = ({ isOpen, onClose, item }): JSX.Element=>{
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const [details, setDetails] = useState(false);
    const [ parcel, setParcel ] = useState(false);
    // const items = {
    //     customer: "Bello Hadi",
    //     status: "assigned"
    // }
    return(
        <>

            <Drawer isOpen={isOpen} placement="right" onClose={onClose}  size="lg">
                    <DrawerOverlay />
                    <DrawerContent>
                        <Flex
                            flexDir="column"
                            height={!details ? '87.875rem' : '95rem'}
                            overflow="scroll"
                            bg="rgba(7, 5, 41, 0.03)">
                                <Flex flexDir="column">
                                <DrawerHead item={item} phn={phn} msg={msg} onClose={onClose}
                         />
                                </Flex>

                            <Flex flexDir="column" height="72.25rem" borderRadius="20px" mt="2rem" bg="#fff">
                            <DrawerContents details={details} setDetails={setDetails} parcel={parcel} setParcel={setParcel} />
                            </Flex>
                        </Flex>
                    </DrawerContent>
            </Drawer>
        </>
    );
}
export default ShipmentOrder;

    