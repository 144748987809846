import {
  Image,
  Text,
  Box,
  Radio, RadioGroup,Stack,Switch,
  Input,
  Flex,
  Button,
  
  InputLeftElement,
  InputGroup
} from '@chakra-ui/react'
import React from 'react'
import Back from '../../../Asset/Settings/back.png'
import Naira from '../../../Asset/Settings/naira.png'


const BusinessPref=({bussPref})=>{
    
    return (
      <>
       
          <Box
            width="52.3125rem"
            height="49.625rem"
            padding="3rem 7.5rem"
            background="rgba(7, 5, 41, 0.07)"
            border-radius="20px"
            display="flex"
            flexDir="column"
            
            justifyContent="space-between">
            <Flex
              width="20.3125rem"
              flexDir="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="16.6875rem">

                <Text color="#1F1F1F" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Set price per/mile(mi)
                </Text>
                <Flex flexDir="column" width="100%">
                  <InputGroup>
                    <InputLeftElement
                      children={<Image src={Naira} width="10px" height="12px" objectFit="cover" />}
                    />
                    <Input
                      alignItems="center"
                      width="100%"
                      height="5.175vh"
                      background="#FFFFFF"
                      borderRadius="4px"
                      focusBorderColor="primary.main"
                      padding="15px 12px 15px 10px"
                    />
                  </InputGroup>
                </Flex>
                <Flex flexDir="column" width="100%">
                  <InputGroup>
                    <InputLeftElement
                      children={<Image src={Naira} width="10px" height="12px" objectFit="cover" />}
                    />
                    <Input
                      alignItems="center"
                      width="100%"
                      height="5.175vh"
                      background="#FFFFFF"
                      borderRadius="4px"
                      focusBorderColor="primary.main"
                      padding="15px 12px 15px 10px"
                    />
                  </InputGroup>
                </Flex>
              
              <Button
                lineHeight="22px"
                fontWeight="500"
                fontSize="14px"
                color="#595956"
                padding="8px 24px"
                flexShrink="0"
                width="7.8125rem"
                height="2.375rem"
                background="rgba(6, 0, 137, 0.15)"
                borderRadius="8px">
                Save changes
              </Button>
            </Flex>
            {/* second main part */}
            <Flex
              flexDir="column"
              justifyContent="space-between"
              width="37.3125rem"
              height="21.3125rem">
              <Flex width="100%" justifyContent="space-between">
                <Text alignSelf="center" fontWeight="600" fontSize="18px" lineHeight="22px" color="#595956">
                  Do you want to automate all order process?
                </Text>
                <Switch size="md" />
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="space-between"
                width="27.625rem"
                height="16.3125rem">
                {/* two text */}
                <Flex
                  width="100%"
                  height="3.9375rem"
                  flexDir="column"
                  justifyContent="space-between">
                  <Text color="#595956" fontWeight="600" fontSize="18px" lineHeight="22px">
                    Who should be responsible for order processing fees?
                  </Text>
                  <Text color="text.100" fontWeight="450" fontSize="18px" lineHeight="17px">
                    Select an option
                  </Text>
                </Flex>
                {/* end two text */}{/* radio */}
                <Flex
                  flexDir="column"
                  justifyContent="space-between"
                  width="11.1875rem"
                  height="10.375rem">
                  <RadioGroup
                  // onChange={setValue}
                  // value={value}
                  >
                    <Stack direction="column">
                      <Radio
                        value="1"
                        background="#FFFFFF"
                        fontWeight="500"
                        fontSize="20px"
                        lineHeight="17px"
                        color="#595956">
                        me
                      </Radio>
                      <Radio
                        value="2"
                        fontWeight="500"
                        background="#FFFFFF"
                        fontSize="20px"
                        lineHeight="17px"
                        color="#595956">
                        My customers
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Button
                    lineHeight="22px"
                    fontWeight="500"
                    fontSize="14px"
                    color="#595956"
                    padding="8px 24px"
                    flexShrink="0"
                    width="7.8125rem"
                    height="2.375rem"
                    background="rgba(6, 0, 137, 0.15)"
                    borderRadius="8px">
                    Save changes
                  </Button>
                </Flex>
                {/* end radi */}
              </Flex>
            </Flex>
            {/* end */}
          </Box>
       
      </>
    )
}

export default BusinessPref;