import { Flex, Image, Text } from '@chakra-ui/react'
import axios from 'axios'
import React,{useEffect, useState} from 'react'
import Back from '../../../Asset/Settings/back.png'
import withLayout from '../../../wrapper/withLayout'
import AddCourier from '../AddCourier/AddCourier'
import CourierProfile from '../CourierProfile/CourierProfile'
import ManageRider from '../ManageRider/ManageRider'
const Courier = () => {
const [isAddCourier,setAddCourier]=useState(true);
const [isTable, setTable] = useState(false)




  return (
    <>
      <Flex maxWidth="100%" mt="2rem" flexDirection="column" >
        {/* Back */}
        <Flex
          flexDir="column"
          alignItems="flex-start"
          justifySelf="flex-start"
          justifyContent="space-between"
          width="5.125rem"
          height="6.3125rem">
          {isTable ? (
            <Flex width="2.5rem" height="2.5rem" alignItems="center" justifyContent="center">
              <Image
                cursor="pointer"
                  onClick={() => {
                    setAddCourier(true)
                    setTable(false)
                  }}
                src={Back}
                alt=""
                width="24px"
                height="24px"
                objectFit="cover"
              />
            </Flex>
          ) : (
            ''
          )}
          <Text color="#000000" fontWeight="600" fontSize="24px" lineHeight="29px">
            Courier
          </Text>
        </Flex>
        {/* end icon */}
        {/* compnents */}
        {isAddCourier ? (
          <AddCourier setAddCourier={setAddCourier} setTable={setTable} />
        ) : isTable ? (
          <ManageRider setAddCourier={setAddCourier} setTable={setTable} />
        ) : (
          <CourierProfile />
        )}
        {/* end compnents */}
      </Flex>
    </>
  )
}

export default withLayout(Courier);
