import React from 'react';
import { Box, Text, Checkbox, Flex, Stack, Progress } from '@chakra-ui/react';
import { RiEBike2Fill } from 'react-icons/ri';
import { FaFlagCheckered } from 'react-icons/fa';

function Card() {
  const value = 0; // Progress bar value set to 0%
  const totalWidth = 100; // Total width of the progress bar

  const progressBarWidth = `${(value / totalWidth) * 100}%`;

  return (
    <Box
      flex="1"
      // width="50%"
      height="250px"
      gap="10px"
      backgroundColor="#ffffff"
      boxShadow="base"
      borderRadius="10px"
      px="10px"
      py="15px"
      m="5px"
    >
      <Box gap={['10px', '50px', '80px']}>
        <Text fontSize={['12px', '14px']} as="b" color="#595956">
          Most visited locations
        </Text>
      </Box>

      <Flex align="center">
        <Box position="relative" flex="1" py="10px">
            <Flex justify="space-between" py="15px" >
                <Text color="#ABA7A7">Ikorodu</Text>
                <Text>{value}%</Text>
            </Flex>
          <Progress value={value} w="100%" h="7px" bg="gray.200" borderRadius="md" />
        </Box>
      </Flex>

      <Flex align="center">
        <Box position="relative" flex="1" py="10px">
        <Flex justify="space-between" pb="10px">
                <Text color="#ABA7A7">Island</Text>
                <Text>{value}%</Text>
            </Flex>
          <Progress value={value} w="100%" h="7px" bg="gray.200" borderRadius="md" />
        </Box>
      </Flex>

      <Flex align="center">
        <Box position="relative" flex="1" py="10px">
        <Flex justify="space-between" pb="10px">
                <Text color="#ABA7A7">Oshodi</Text>
                <Text>{value}%</Text>
            </Flex>
          <Progress value={value} w="100%" h="7px" bg="gray.200" borderRadius="md" />
        </Box>
      </Flex>
    </Box>
  );
}

export default Card;
