import React, { useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  Switch,
  Image,
  PinInputField,
  PinInput,
  HStack,
  Button
} from '@chakra-ui/react'
import { CustomBox } from './CustomBox'
import eyeImg from '../../../Asset/Settings/eye.png'
import eyeImgCross from '../../../Asset/Settings/Vector.png'
import { ChangePassword } from './ChangePassword'

const LoginSettings=()=>{
  const [isClicked,setIsClicked]=useState(false)
   const [isChangePass, setChangePass] = useState(false)
   const [isForgotPass, setForgotPass] = useState(false)
const login=true

    return (
      <>
        {' '}
        
        <Flex width="100%" height="100vh" justifyContent="center">
          {/* container */}
          {!isChangePass ? (
            <Flex
              flexDir="column"
              width="578px"
              height="487px"
              padding="2rem 3rem"
              borderRadius="20px"
              justifyContent="space-between"
              background="rgba(7, 5, 41, 0.07)">
              {/* inner wrapper */}
              <Flex
                flexDir="column"
                width="30.125rem"
                height="26.4375rem"
                justifyContent="space-between">
                <Text fontWeight="500" fontSize="24px" lineHeight="29px" color="#656566">
                  Login settings
                </Text>
                {/* current password */}
                <Flex
                  flexDir="column"
                  width="30rem"
                  height="4.625rem"
                  justifyContent="space-between">
                  <Text fontWeight="500" fontSize="16px" lineHeight="22px" color="#656566">
                    Current Password
                  </Text>
                  <InputGroup>
                    <Input
                      type={!isClicked ? 'password' : 'text'}
                      width="100%"
                      height="2.75rem"
                      background="#FFFFFF"
                      borderRadius="4px"
                      padding="10px 12px 10px 10px"
                    />
                    <InputRightElement
                      mt="5px"
                      children={
                        <Image
                          src={isClicked ? eyeImgCross : eyeImg}
                          width="24px"
                          height="24px"
                          objectFit="contain"
                          onClick={() => setIsClicked(!isClicked)}
                        />
                      }
                    />
                  </InputGroup>
                </Flex>
                {/* end current password */}

                {/* custom box */}
                <CustomBox
                  flexWidth="30.0625rem"
                  flexHeight="3.375rem"
                  wrapperHeight="8.75rem"
                  Text1="Change password"
                  Text2="Forgot password"
                  color="#AAAAAA"
                  login={login}
                  size="16px"
                  weight="450"
                  setChangePass={setChangePass}
                  setForgotPass={setForgotPass}
                />
                {/* end custom box */}
                {/* 2fA */}
                <Flex width="30.0625rem" height="4.25rem" justifyContent="space-between">
                  {/* text flex */}
                  <Flex
                    flexDir="column"
                    width="22.625rem"
                    height="4.25rem"
                    justifyContent="space-between">
                    <Text fontWeight="500" fontSize="18px" lineHeight="22px" color="#AAAAAA">
                      2FA
                    </Text>
                    <Text fontWeight="500" fontSize="16px" lineHeight="22px" color="#656566">
                      Activate 2 factor authentication for extra security
                    </Text>
                  </Flex>
                  {/* end text flex */}
                  <Switch alignSelf="flex-end" size="md" />
                </Flex>
                {/* end 2FA */}
              </Flex>
              {/* end inner wrapper */}
            </Flex>
          ) : !isForgotPass ? (
            <ChangePassword />
          ) : (
            ''
          )}
          {/* end container */}
        </Flex>
      </>
    )
}

export default LoginSettings;