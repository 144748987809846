import React, { useCallback, useState } from 'react'
import { Box, Text, Flex, List, ListItem, UnorderedList } from '@chakra-ui/react'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts'
import { BsBoxArrowInLeft } from 'react-icons/bs'

function Deliveries() {
  const data = [
    { name: 'In transit', value: 370, color: '' },
    { name: 'Delivered', value: 120 },
    { name: 'Delayed', value: 500 },
    { name: 'Pending', value: 240 }
  ]

  const COLORS = ['#F9C567', '#CD0B3A', '#070529', '#F1F1F0']

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + (index === 0 ? -20 : 10) // Adjusted x coordinate based on color index
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    const textColor = COLORS[index % COLORS.length] === '#F1F1F0' ? 'black' : 'white'

    

    return (
      <text
        x={x}
        y={y}
        fill={textColor}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  return (
    <Flex
      height="250px"
      gap="20px"
      width="60%"
      backgroundColor="#ffffff"
      boxShadow="base"
      borderRadius="10px"
      px="20px"
      py="20px"
      pb="25px"
      mr="50px">
      <ResponsiveContainer>
        <PieChart width={250} height={250}>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            iconType="circle"
            color="#ABA7A7"
           
          />
          <Pie
            data={data}
            cx={100} // Adjusted cx coordinate
            cy={100}
            innerRadius={30}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={90}
            fill="#8884d8"
            name="Mike"
            dataKey="value">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Flex>
  )
}

export default Deliveries
