import React from "react";
import { Modal, ModalOverlay, ModalBody, ModalContent, Box, Image, Heading, Text, Flex, Button } from "@chakra-ui/react";
import box from "../../../../Asset/box.png";
const ConfirmCancelOrder = ({ isOpen, onClose })=>{
    return(
        <>
            <Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInBottom'>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                       <Box mt={3} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Image src={box} boxSize="56px" alt="sendrail" />
                            <Heading as="h5" color="#000" fontFamily="sans-serif" fontSize="23px" my={5}>Order Alert</Heading>
                            <Text color="#595956" fontWeight="500px" fontSize="20px">Order#101 has been cancelled.</Text>
                            <Box my={6} width="50%" display="flex" justifyContent="space-between" mt={5}>
                                <Button colorScheme="#333344" variant="outline" onClick={onClose}>Cancel</Button>
                                <Button bg="rgba(7, 5, 41, 0.9)" colorScheme="#fff">View</Button>
                            </Box>
                        </Box> 
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
export default ConfirmCancelOrder;