import React, { useState } from 'react';
import { Box, Button, Text, Flex, Select, Spacer } from '@chakra-ui/react';
import { Chart as ChartJS, LineController, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(LineElement, LineController, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

function LineChart() {
  const [selectedOption, setSelectedOption] = useState('');
  const [earnedVisible, setEarnedVisible] = useState(true);
  const [expensesVisible, setExpensesVisible] = useState(true);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleEarnedClick = () => {
    setEarnedVisible(!earnedVisible);
  };

  const handleExpenseClick = () => {
    setExpensesVisible(!expensesVisible);
  };

  const data = {
    labels: ['Mon', '', 'Tue', '', 'Wed', '', 'Thur', '', 'Fri', '', 'Sat', '', 'Sun'],
    datasets: [
      earnedVisible && {
        label: '',
        data: [0, 450000, 200000, '', 700000, 300000, 500000, 150000, 850000,'', 650000, 100000], // Values in Nigerian Naira (₦)
        backgroundColor: '#F9C567',
        borderColor: '#F9C567',
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
        tension: 0.4
      },
      expensesVisible && {
        label: '',
        data: [ '', '', '', 600000, 300000, 900000, 0, 0, 0, 0], // Values in Nigerian Naira (₦)
        backgroundColor: '#07052933',
        borderColor: '#07052933',
        borderWidth: 1,
        fill: false,
        pointRadius: 0,
        tension: 0.4
      }
    ].filter(Boolean)
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: false // Do not use point style (circle) for legend labels
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          
        },

      },
      y: {
        min: 0,
        max: 1000000, // Maximum value in Nigerian Naira (₦)
        ticks: {
          stepSize: 200000, // Step size for each tick
          callback: (value) => `₦${value / 1000}k` // Format the tick label as ₦0k, ₦200k, ₦400k, etc.
        },

        border: {
          display: false,
        }
    
      }
    }
  };


  return (
    <Box py="48px">
      <Box
        w="95%"
        h="100%"
        p="10px"
        overflow="auto"
        position="relative"
        bg="ffffff"
        boxShadow="base"
        m="auto"
        my="20px"
        px="25px"
        pt="10px"
        pb="25px"
      >
        <Flex align="center" justify="space-between" my="25px">
          <Text fontSize="28px">Revenue</Text>
        <Flex align="center" justify="space-between" >
          <Flex gap={2} align="center">
            <Flex px="40px" gap="32px">
              <Flex align="center" >
              <Text backgroundColor="#F9C567" borderRadius="50%" width="15px" height="15px"></Text>
                <Text fontSize="12px" px="5px" color="#ABA7A7">Earned</Text>
              </Flex>
              <Flex align="center">
                <Text backgroundColor="#07052933" borderRadius="50%" width="15px" height="15px"></Text>
                <Text fontSize="12px" px="5px" color="#ABA7A7">Expense</Text>
              </Flex>
            </Flex>
            <Button
              background="#ffffff"
              boxShadow="base"
              color="#ABA7A7"
              size="xs"
              variant={earnedVisible ? 'solid' : 'outline'}
              onClick={handleEarnedClick}
            >
              Earned
            </Button>
            <Button
              background="#ffffff"
              boxShadow="base"
               color="#ABA7A7"
              size="xs"
              variant={expensesVisible ? 'solid' : 'outline'}
              onClick={handleExpenseClick}
            >
              Expenses
            </Button>
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              width="130px"
              height="30px"
              fontSize="12px"
              color="#A0A0AA"
              bg="white"
              border="none"
              outline="none"
              boxShadow="base"
              py={1}
            >
              <option value="this-month">This month</option>
              <option value="next-month">Next month</option>
              <option value="last-month">Previous month</option>
            </Select>
          </Flex>
        </Flex>
        </Flex>
        <Box backgroundColor="#FBFBFB" px="40px" py="25px">
        {/* @ts-ignore */}
          <Line data={data} options={options} />
        </Box>
      </Box>
    </Box>
  );
}

export default LineChart;
