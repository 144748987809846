import React from 'react'
import { Box, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Image, TableContainer, Link } from '@chakra-ui/react'
import nhistory from '../../../../Asset/Transactions/nhistory.png'
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Shipment from '../../../AdminDashboard/Shipment/index';
import { Link as RouterLink } from 'react-router-dom';

function OrderTable() {
  const data = [
    {
      destination: 'Ikeja-Ikorodu',
      courierName: 'Merlin Morgana',
      orderStatus: 'Assigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'In transit'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: 'Merlin Morgana',
      orderStatus: 'Assigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'In transit'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: 'Merlin Morgana',
      orderStatus: 'Assigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'In transit'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: '-',
      orderStatus: 'Unassigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'Cancelled'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: '-',
      orderStatus: 'Unassigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'Pending'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: '-',
      orderStatus: 'Unassigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'Pending'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: 'Merlin Morgana',
      orderStatus: 'Assigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'Cancelled'
    },
    {
      destination: 'Ikeja-Ikorodu',
      courierName: 'Merlin Morgana',
      orderStatus: 'Assigned',
      orderId: '#110934A2',
      date: '12-20-2023',
      status: 'Delivered'
    }
  ]

  const getStatusStyles = (status) => {
    switch (status) {
      case 'Assigned':
        return {
          color: '#079638',
          background: '#E6FDEEC3',
          padding: "0"
        };
      case 'Unassigned':
        return {
          color: '#CD0B3A',
          background: '#F7D8DFC5',
          p: "0"
        };
      case 'Pending':
        return {
          color: '#F9C567',
          background: '#FDF3D8C5',
          p: "0"
        };
      case 'Cancelled':
        return {
          color: '#CD0B3A',
          background: '#F7D8DFC5',
          p: "0"
        };
      case 'Delivered':
        return {
          color: '#079638',
          background: '#E6FDEEC3',
          p: "0"
        };
      case 'In transit':
        return {
          color: '#595956',
          background: '#07052912',
          p: "0"
        };
      default:
        return {};
    }
  };

  return (
    <Box width="74%" backgroundColor="#ffffff" boxShadow="base" borderRadius="10px" px="10px" py="15px" m="5px">
      <Box fontSize="14px" w="100%">
        <Flex pb="40px" justify="space-between" align="center">
          <Text fontSize="28px" as="b">Recent Orders</Text>
          <Flex color="#F9C567" fontSize="14px" align="center">
            <Link  as={RouterLink} to="/Shipment">See all</Link>
            <MdKeyboardArrowRight />
          </Flex>
        </Flex>
        <TableContainer height="380px" overflow="auto" overflowX="scroll" overflowY="scroll">
          <Table size="md">
            <Thead fontSize="14px">
              <Tr bg="#07052912">
                <Th>
                  <Box>Destination</Box>
                </Th>
                <Th>
                  <Box>Courier name</Box>
                </Th>
                <Th>
                  <Box>Order status</Box>
                </Th>
                <Th>
                  <Box>Order id</Box>
                </Th>
                <Th>
                  <Box>Date</Box>
                </Th>
                <Th>
                  <Box>Status</Box>
                </Th>
                <Th>
                  <Box>Action</Box>
                </Th>
              </Tr>
            </Thead>
            {data.length === 0 ? (
              <Tbody>
                <Tr>
                  <Td colSpan={7}>
                    <Flex mt="120px" justifyContent="center">
                      <Image src={nhistory} alt="" width="349px" height="459px" />
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            ) : (
              <Tbody>
                {data.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.destination}</Td>
                    <Td>{item.courierName}</Td>
                    <Td>
                      <Box borderRadius="md" fontWeight="semibold" w="73px" m="auto" p="0" {...getStatusStyles(item.orderStatus)}>
                        {item.orderStatus}
                      </Box>
                    </Td>
                    <Td>{item.orderId}</Td>
                    <Td>{item.date}</Td>
                    <Td>
                      <Box borderRadius="sm" fontWeight="semibold" {...getStatusStyles(item.status)}>
                        {item.status}
                      </Box>
                    </Td>
                    <Td>
                      <BiDotsVerticalRounded />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default OrderTable;