import React from 'react'
import { Box, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Grid, GridItem } from '@chakra-ui/react'
import DashboardLayout from '../../DashboardLayout'
import LineChart from './lineChart'
import Card from './card'
import Visit from './visit'
import Deliveries from './deliveries'
import OrderTable from './orderTable'
import Slider from './slider'
import AddCard from './addCard'

function Index(): JSX.Element {
  return (
    <DashboardLayout>
      <Box w="95%" m="auto">
        <Slider />
        <AddCard />
        <LineChart />
        <Grid templateColumns="1fr 2fr" gap={4} overflowX="hidden" width="100%">
          <GridItem >
            <Text py="20px" color="#595956" as="b" m="20px">
              Deliveries in transit
            </Text>
            <Box>
              <Card />
              <Card />
              <Card />
              <Card />
            </Box>
          </GridItem>

          <GridItem gap={4}>
            <Grid templateColumns="1fr 2fr" gap={4} mb={4}>
            <Visit />
            <Deliveries />
            </Grid>
            <OrderTable />
          </GridItem>
          
        </Grid>
      </Box>
    </DashboardLayout>
  )
}

export default Index
