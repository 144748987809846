export const copyTextToClipboard = async (text:string) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
};



export const handleError = (error) => {
  

  if (error?.response) {
    console.log(error.response?.data);
      console.log(error.response?.status);
      console.log(error.response?.headers);
  } else if (error.request) {
     
      console.log(error.request);
    } else {
     
      console.log('Error', error.message);
    }

  console.log({config:error.config});
}







export const enum MoneyReceived {
  STATUS = 'Status',
  SENDER = 'Name',
  BANKNAME = 'Bank',
  ACCOUNTNUM = 'Account number',
  MESSAGETYPE = 'Message Type',
  AMOUNT = 'Amount',
  TRANSACTIONID = 'Transaction ID'
}

export const enum MoneySent {
  STATUS = 'Status',
  SENDER = 'recipient Name',
  BANKNAME = 'Bank',
  ACCOUNTNUM = 'Account number',
  MESSAGETYPE = 'Message Type',
  AMOUNT = 'Amount',
  TRANSACTIONID = 'Transaction ID',
  CHARGES = 'Charges'
}


  export const currencyFormatter = (val: string) => {
    const number = Number(val)
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(number)
  }

  export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

   export const transformedVal = (val): string => {
     if (typeof val !== 'string') {
       return String(val).replace(String(val).substring(3, 8), '***')
     }
     return val.replace(val.substring(3, 8), '***')
   }