import {
  Flex,
  Box,
  Text,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormLabel,
  Button,
  Center,
  Image
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import './BussInfo.css'
import { FiEdit2 } from 'react-icons/fi'
import camera from '../../../Asset/Rider/camera.png'
import { useAxiosPrivate } from '../../../lib/hooks/useAxiosPrivate'
const BusinessInfo = ({ bussInfo }) => {
  const {
    businessAddress,
    businessName,
    city,
    country,
    email,
    phoneNumber,
    profilePicture,
    state,
    type
  } = bussInfo
  console.log({ phoneNumber })

  const [infoState, setInfoState] = useState({
    businessAdd: businessAddress,
    bussName: businessName,
    citys: city,
    countr: country,
    emailD: email,
    phoneNum: phoneNumber,
    profilePic: profilePicture,
    region: state,
    typ: type
  })

  const axiosPrivate = useAxiosPrivate()
  const [isEditAdd, setIsEditAdd] = useState(false)
  const [isEditBuss, setIsEditBuss] = useState(false)
  const [isEditPhn, setIsEditPhn] = useState(false)
  const [isEditCity, setIsEditCity] = useState(false)
  const [isEditProf] = useState(false)
  const [isEditState, setIsEditState] = useState(false)
  const [isEditCon, setIsEditCon] = useState(false)
  const [isEditEmail, setIsEditEmail] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    setInfoState({ ...infoState, [name]: value })
  }
  let { businessAdd, bussName, citys, countr, emailD, phoneNum, profilePic, region, typ } =
    infoState
  const [isLoading, setIsLoading] = useState(false)

  const createBussInfo = useCallback(async () => {
    setIsLoading(true)
    const resp = await axiosPrivate.patch('/settings/me/business-info', {
      profilePicture: profilePic,
      phoneNumber: phoneNum,
      email: emailD,
      businessAddress: businessAdd,
      businessName: bussName,
      city: citys,
      country: countr,
      state: region,
      type: 'Logistic'
    })
    setIsLoading(false)
    console.log(resp)
    if (resp) {
      setIsEditAdd(false)
      setIsEditBuss(false)
      setIsEditPhn(false)
      setIsEditCity(false)
      setIsEditState(false)
      setIsEditCon(false)
      setIsEditEmail(false)
    }
  }, [businessAdd, bussName, citys, countr, emailD, phoneNum, profilePic, region, typ])
  useEffect(() => {
    setInfoState({
      businessAdd: businessAddress,
      bussName: businessName,
      citys: city,
      countr: country,
      emailD: email,
      phoneNum: phoneNumber,
      profilePic: profilePicture,
      region: state,
      typ: type
    })
  }, [
    businessAddress,
    businessName,
    city,
    country,
    email,
    phoneNumber,
    profilePicture,
    state,
    type
  ])
  return (
    <>
      <Text mt="10px" color="#000000" fontWeight="500" fontSize="24px" lineHeight="29px">
        Business Information
      </Text>
      <Center>
        <Box
          mt="56px"
          width="70%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          padding="32px 48px"
          boxSizing="border-box"
          background="rgba(7, 5, 41, 0.07)"
          borderRadius="20px">
          <Flex flexDir="column">
            <Text color="#656566" fontWeight="500" fontSize="24px" lineHeight="29px">
              Business Information
            </Text>
            <Box className="Wrapper">
              {!isEditProf ? (
                <Input type="file" className="my_upload" value={profilePicture} />
              ) : (
                <Input
                  type="file"
                  className="my_upload"
                  value={profilePic}
                  name="prfilePic"
                  onChange={handleChange}
                />
              )}
              <Image
                src={camera}
                alt="camera"
                position="absolute"
                top="45%"
                right="0"
                width="32px"
                height="32px"
                objectFit="cover"
              />
            </Box>
          </Flex>
          <Flex flexDir="column" width="100%">
            <Flex justifyContent="space-between" mt="70px">
              <FormControl>
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Business name
                </FormLabel>
                <InputGroup>
                  {!isEditBuss ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={businessName}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={bussName}
                      name="bussName"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditBuss ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsEditBuss(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>

              <FormControl ml="60px">
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Business address
                </FormLabel>
                <InputGroup>
                  {!isEditAdd ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={businessAddress}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={businessAdd}
                      name="businessAdd"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditAdd ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsEditAdd(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" mt="70px">
              <FormControl>
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Email
                </FormLabel>
                <InputGroup>
                  {!isEditEmail ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={email}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={emailD}
                      name="emailD"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditEmail ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsEditEmail(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>

              <FormControl ml="60px">
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Phone number
                </FormLabel>
                <InputGroup>
                  {!isEditPhn ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={phoneNumber}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={phoneNum}
                      name="phoneNum"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditPhn ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsEditPhn(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" mt="70px">
              <FormControl>
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  City
                </FormLabel>
                <InputGroup>
                  {!isEditCity ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={city}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={citys}
                      name="citys"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditCity ? (
                    <InputRightElement
                      mt="4px"
                      cursor="pointer"
                      onClick={() => setIsEditCity(true)}
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>

              <FormControl ml="60px">
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  State
                </FormLabel>
                <InputGroup>
                  {!isEditState ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={state}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={region}
                      name="region"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditState ? (
                    <InputRightElement
                      cursor="pointer"
                      onClick={() => setIsEditState(true)}
                      mt="4px"
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" mt="70px">
              <FormControl>
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Country
                </FormLabel>
                <InputGroup>
                  {!isEditCon ? (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={country}
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      value={countr}
                      name="countr"
                      onChange={handleChange}
                    />
                  )}
                  {!isEditCon ? (
                    <InputRightElement
                      cursor="pointer"
                      onClick={() => setIsEditCon(true)}
                      mt="4px"
                      children={<FiEdit2 width="16px" height="16px" />}
                    />
                  ) : (
                    ''
                  )}
                </InputGroup>
              </FormControl>

              <FormControl ml="60px" opacity="0">
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Country
                </FormLabel>
                <InputGroup>
                  <Input
                    alignItems="center"
                    width="100%"
                    height="54px"
                    background="#FFFFFF"
                    borderRadius="4px"
                    value={countr}
                    name="countr"
                    onChange={handleChange}
                  />
                  <InputRightElement mt="4px" children={<FiEdit2 width="16px" height="16px" />} />
                </InputGroup>
              </FormControl>
            </Flex>
            <Button
              padding="16px 32px"
              width="168px"
              height="54px"
              background="rgba(7, 5, 41, 0.9)"
              borderRadius="8px"
              fontWeight="500"
              fontSize="16px"
              lineHeight="22px"
              color="#FFFFFF"
              mt="48px"
              _hover={{
                background: 'rgba(7, 5, 41, 0.9)'
              }}>
              Save Changes
            </Button>
          </Flex>
        </Box>
      </Center>
    </>
  )
}

export default BusinessInfo
