import React, { useCallback, useReducer,useState,useMemo } from 'react'
import '../Style/signUp.css'
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode'
import {
  Flex,
  Box,
  Image,
  Button,
  Text,
   InputGroup,
  InputRightElement,
  Input,
  
  useMediaQuery,
  Highlight
} from '@chakra-ui/react'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom'
import reducer from '../reducers/signupReducer'
import { FormHead } from '../Component/FormHead/FormHead'
import correct from '../Asset/signUp/correct.png'
import error from '../Asset/signUp/error.png'
import empty from '../Asset/signUp/default.png'
import sendrail from '../Asset/signUp/SENDRAILS.png'
import Lshape from '../Asset/signUp/Vector 1.png'
import eyeImg from '../Asset/Settings/eye.png'
import eyeImgCross from '../Asset/Settings/Vector.png'
import { signUpApi } from '../api/sign_up'
import { SideBarOnboard } from '../Component/SideBarOnbard/SideBarOnboard'
import { useAuth } from '../lib/hooks/useAuth';
// import countryList from 'react-select-country-list'
import { getCountries, getStates } from 'country-state-picker';

const initialState = {
  fullName: '',
  select: '',
  bussAdd: '',
  stateVal: '',
  country: '',
  phn: '',
  bussName: '',
 
  bussEmail: '',
  email: '',
  password: ''
}
function SignUp() {
    const { setAuth } = useAuth();
    const [isClicked,setIsClicked]=useState(false)
    const signup=true;
  const [isNext, setIsNext] = useState(false)
  const [isSmallerThan740] = useMediaQuery('(max-width: 740px)')
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)')
  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
  const [isSmallerThan530] = useMediaQuery('(max-width: 530px)')
const [stateOptions, setStateptions] = useState([])
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, initialState)
   const [isLoading, setIsLoading] = useState(false)
  // const options = useMemo(() => countryList().getData(), [])
    const options = getCountries().map(({ name,code }) => ({ value:code,label:name }))
const genderOptions = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  
];



const colorStyles={
  control:(styles,{isFocused})=>{
      return {
        ...styles,
        marginTop: '0.5rem',
        height: '3rem',
        fontSize: '15px',
        lineHeight: '22px',
        background: 'rgba(6, 0, 137, 0.05)',
        
        borderColor: isFocused ? '#F9C567' : '',
        color: '#ABA7A7'
      }
  },
}
  
  // const passReg=/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const reset = useCallback(() => dispatch({ type: 'reset', payload: '' }), []);

  const lowerCase = /[a-z]/
  const upper = /[A-Z]/
  const numb = /[0-9]/
  const {
    fullName,
    select,
    bussAdd,
    stateVal,
    country,
    phn,
    bussName,
    
    bussEmail,
    email,
    password
  } = state
 

 console.log(stateVal.value)
  
  const sendSignUp = useCallback(async () => {
    setIsLoading(true)
     const config = {
 fullName,
  gender:select.value,
  email,
  password,
  phoneNumber:phn,
  businessInfo:{
  name:  bussName,
    type: 'Logistic',
    email: bussEmail,
    address: bussAdd,
    state: stateVal.value,
    country: country.label,
  }
  }

   
    const resp = await signUpApi(config);
     setIsLoading(false)
    console.log({signup:resp});
    
  if (resp) {
    const accessToken = resp?.data?.token;
  const xAccess = resp?.data['x_access'];
// setMsg(resp?.data?.message);
  localStorage.setItem ("token", accessToken)
  localStorage.setItem ("access", xAccess)
navigate('/verify', { state: { email } })
 }

   }, [
    fullName,
  select, bussAdd, stateVal, country, phn, bussName,bussEmail, email, password
  ])

  return (
    <Flex width="100%" height="76.5rem">
      <SideBarOnboard
        isSmallerThan530={isSmallerThan530}
        isSmallerThan1024={isSmallerThan1024}
        isSmallerThan900={isSmallerThan900}
        isSmallerThan740={isSmallerThan740}
        signup={signup}
      />
      {/* inner */}
      <Flex
        flexDir="column"
        width="61.8%"
        height="100%"
        pt={isSmallerThan900 ? '1rem' : isSmallerThan1024 ? '3rem' : '5rem'}
        px={isSmallerThan900 ? '2rem' : isSmallerThan1024 ? '3rem' : '6.4375rem'}>
        <FormHead />
        <Text
          mt={['20px', '35px', '58px']}
          alignSelf="center"
          flexShrink="0"
          fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '18px' : '20px'}
          fontWeight="500"
          lineHeight={isSmallerThan900 ? '14px' : isSmallerThan1024 ? '18px' : '22px'}
          color="#000">
          {!isNext ? 'Personal Information' : 'Business Information'}
        </Text>
        {!isNext ? (
          <Flex
            flexDirection="column"
            alignSelf="center"
            justifyContent="space-between"
            width={isSmallerThan900 ? '23rem' : isSmallerThan1024 ? '30rem' : '36.75rem'}
            height={isSmallerThan900 ? '15rem' : isSmallerThan1024 ? '17rem' : '22.3125rem'}
            mt={['15px', '20px', '32px']}>
            <Flex maxWidth="100%" flexDir="column">
              <Text
                color="text.50"
                flexShrink="0"
                fontWeight="500"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                Full name
              </Text>
              <Input
                mt={['0.1rem', '0.3rem', '0.5rem']}
                value={fullName}
                onChange={(e) => dispatch({ type: 'full name', payload: e.target.value })}
                placeholder="Enter your name"
                fontWeight="450"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                maxWidth="100%"
                height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
                background="rgba(6, 0, 137, 0.05)"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
            {/* Gender */}
            <Flex maxWidth="100%" flexDir="column" mt={isSmallerThan900 ? '0.3rem' : ''}>
              <Text
                color="text.50"
                flexShrink="0"
                fontWeight="500"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                Gender
              </Text>
              <Select
                value={select}
                options={genderOptions}
                onChange={(select) => dispatch({ type: 'select inp', payload: select })}
                styles={colorStyles}
              />
            </Flex>
            {/* end Gender */}
            {/* Email */}
            <Flex maxWidth="100%" flexDir="column" mt={isSmallerThan900 ? '0.3rem' : ''}>
              <Text
                color="text.50"
                flexShrink="0"
                fontWeight="500"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                Email
              </Text>
              <Input
                mt={['0.1rem', '0.3rem', '0.5rem']}
                value={email}
                onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
                placeholder="example@gmail.com"
                fontWeight="450"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                maxWidth="100%"
                height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
                background="rgba(6, 0, 137, 0.05)"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
            {/* end Email */}

            {/* phn */}
            <Flex maxWidth="100%" flexDir="column" mt={isSmallerThan900 ? '0.3rem' : ''}>
              <Text
                color="text.50"
                flexShrink="0"
                fontWeight="500"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                Phone Number
              </Text>
              <Input
                mt={['0.1rem', '0.3rem', '0.5rem']}
                placeholder="Enter your phone number"
                value={phn}
                onChange={(e) => dispatch({ type: 'phone number', payload: e.target.value })}
                fontWeight="450"
                fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                maxWidth="100%"
                height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
                background="rgba(6, 0, 137, 0.05)"
                _placeholder={{
                  color: 'text.100'
                }}
              />
            </Flex>
            {/* end phn */}
          </Flex>
        ) : (
          // Business Info

          <Flex
            flexDir="column"
            mt={['15px', '20px', '32px']}
            alignSelf="center"
            justifyContent="space-between"
            width="588px"
            height="36.8625rem">
            {/* first half */}
            <Flex flexDir="column" justifyContent="space-between" width="100%" height="77.49%">
              {/* bussName */}
              <Flex
                maxWidth="100%"
                flexDir="column"
                height="4.875rem"
                justifyContent="space-between">
                <Text
                  color="text.50"
                  flexShrink="0"
                  fontWeight="500"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  Business name
                </Text>
                <Input
                  value={bussName}
                  onChange={(e) => dispatch({ type: 'business name', payload: e.target.value })}
                  placeholder="Enter a name"
                  fontWeight="450"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                  maxWidth="100%"
                  height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
                  background="rgba(6, 0, 137, 0.05)"
                  _placeholder={{
                    color: 'text.100'
                  }}
                />
              </Flex>
              {/* end bussName */}

              {/* bussEmail */}
              <Flex
                maxWidth="100%"
                flexDir="column"
                height="4.875rem"
                justifyContent="space-between">
                <Text
                  color="text.50"
                  flexShrink="0"
                  fontWeight="500"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  Business Email
                </Text>
                <Input
                  placeholder="Enter company email "
                  value={bussEmail}
                  onChange={(e) => dispatch({ type: 'Buss Email', payload: e.target.value })}
                  fontWeight="450"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                  maxWidth="100%"
                  height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
                  background="rgba(6, 0, 137, 0.05)"
                  _placeholder={{
                    color: 'text.100'
                  }}
                />
              </Flex>
              {/* end bussEmail */}
              <Flex
                maxWidth="100%"
                flexDir="column"
                height="4.875rem"
                justifyContent="space-between">
                <Text
                  color="text.50"
                  flexShrink="0"
                  fontWeight="500"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  Business Address
                </Text>
                <Input
                  placeholder="Enter an Address"
                  value={bussAdd}
                  onChange={(e) => dispatch({ type: 'Buss Address', payload: e.target.value })}
                  fontWeight="450"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                  maxWidth="100%"
                  height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
                  background="rgba(6, 0, 137, 0.05)"
                  _placeholder={{
                    color: 'text.100'
                  }}
                />
              </Flex>
              {/* end bussAddress */}
              {/* state */}
              <Flex
                maxWidth="100%"
                flexDir="column"
                height="4.875rem"
                justifyContent="space-between">
                <Text
                  color="text.50"
                  flexShrink="0"
                  fontWeight="500"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  Country
                </Text>
                <Select
                  options={options}
                  value={country}
                  onChange={(country) => {
                    dispatch({ type: 'country', payload: country })
                    const states = getStates(country.value).map((state) => ({
                      value: state,
                      label: state
                    }))
                    setStateptions(states)
                  }}
                  styles={colorStyles}
                />
              </Flex>
              {/* end state */}
              {/* Country */}
              <Flex
                maxWidth="100%"
                flexDir="column"
                height="4.875rem"
                justifyContent="space-between">
                <Text
                  color="text.50"
                  flexShrink="0"
                  fontWeight="500"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  State
                </Text>
                <Select
                  options={stateOptions}
                  value={stateVal}
                  onChange={(stateVal) => dispatch({ type: 'state val', payload: stateVal })}
                  styles={colorStyles}
                />
              </Flex>
              {/* end country */}
            </Flex>
            {/* end first half */}
            {/* 2nd half password  */}
            <Flex flexDir="column" justifyContent="space-between" width="100%" height="20.51%">
              <Flex flexDir="column" justifyContent="space-between" width="100%" height="4.875rem">
                <Text
                  color="text.50"
                  flexShrink="0"
                  fontWeight="500"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '18px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  Create password
                </Text>
                <InputGroup>
                  <Input
                    type={!isClicked ? 'password' : 'text'}
                    value={password}
                    onChange={(e) => dispatch({ type: 'password', payload: e.target.value })}
                    placeholder="******"
                    fontWeight="450"
                    fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '15px'}
                    lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                    maxWidth="100%"
                    height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '' : '3rem'}
                    background="rgba(6, 0, 137, 0.05)"
                    _placeholder={{
                      color: 'text.100'
                    }}
                  />
                  <InputRightElement
                    mt="8.5px"
                    children={
                      <Image
                        src={isClicked ? eyeImgCross : eyeImg}
                        width="24px"
                        height="24px"
                        objectFit="contain"
                        onClick={() => setIsClicked(!isClicked)}
                      />
                    }
                  />
                </InputGroup>
              </Flex>
              <Flex width={!password?"16.1875rem":password.length >= 8 ?"6rem":"15rem"} height="1.375rem"  justifyContent="space-between">
                <Image
                  src={!password ? empty : password.length >= 8 ? correct : error}
                  alt="Sendrail"
                  width="16px"
                  height="16px"
                  objectFit="cover"
                  alignSelf="center"
                />
                <Text
                  color={!password ? 'text.200' : password.length >= 8?'green':"red" }
                  flexShrink="0"
                  fontWeight="300"
                  fontSize={isSmallerThan900 ? '10px' : isSmallerThan1024 ? '13px' : '12px'}
                  lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                  {!password
                    ? 'Password should be at least 8 character long'
                    : password.length >= 8
                    ? 'Valid Password'
                    : 'Invalid password,at least 8 character long'}
      
                </Text>
              </Flex>
            </Flex>
            {/* end second half */}
          </Flex>

          // end Bussiness info
        )}
        <Flex
          width={isSmallerThan900 ? '25rem' : isSmallerThan1024 ? '30rem' : '36.75rem'}
          flexDir="column"
          alignSelf="center"
          mt="3.75rem">
          {!isNext ? (
            <Button
              color="#fff"
              borderRadius="4px"
              padding={isSmallerThan900 ? '' : isSmallerThan1024 ? '' : '1rem 2rem'}
              fontWeight="500"
              fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
              lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
              maxWidth="100%"
              height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
              onClick={() => setIsNext(true)}
              background="primary.main"
              _hover={{
                background: 'primary.main'
              }}>
              Next
            </Button>
          ) : (
            <Button
              color="#fff"
              borderRadius="4px"
              padding={isSmallerThan900 ? '' : isSmallerThan1024 ? '' : '1rem 2rem'}
              fontWeight="500"
              fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
              lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
              maxWidth="100%"
              height={isSmallerThan900 ? '2.5rem' : isSmallerThan1024 ? '2.5rem' : '3rem'}
              background="primary.main"
              isLoading={isLoading ? true : false}
              loadingText="Loading"
              spinnerPlacement="start"
              variant={isLoading ? 'outline' : 'filled'}
              onClick={() => {
                sendSignUp()
                reset()
              }}
              _hover={{
                background: 'primary.main'
              }}>
              Sign up
            </Button>
          )}
        </Flex>
        <Flex
          width={isSmallerThan900 ? '25rem' : isSmallerThan1024 ? '30rem' : '36.75rem'}
          flexDir="column"
          alignSelf="center">
          {!isNext ? (
            <Flex
              mt="2rem"
              justifyContent="space-between"
              maxWidth={isSmallerThan900 ? '11rem' : isSmallerThan1024 ? '14rem' : '17rem'}>
              <Text
                color="text.100"
                flexShrink="0"
                fontWeight="500"
                fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                Already have an account?
              </Text>
              <Button
                variant="link"
                fontWeight="700"
                fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}
                color="primary.main"
                onClick={() => navigate('/login')}
                _hover={{
                  textDecoration: 'none'
                }}>
                Sign in
              </Button>
            </Flex>
          ) : (
            <Flex
              alignSelf="center"
              mt={isSmallerThan900 ? '0.5rem' : ''}
              flexWrap="wrap"
              maxWidth={isSmallerThan900 ? '' : isSmallerThan1024 ? '' : '36.75rem'}
              height="2.75rem">
              <Text
                color="text.50"
                fontWeight="450"
                fontSize={isSmallerThan900 ? '12px' : isSmallerThan1024 ? '15px' : '18px'}
                lineHeight={isSmallerThan900 ? '15px' : isSmallerThan1024 ? '18px' : '22px'}>
                By clicking Continue, you acknowledge that you have read and accepted the{' '}
                <Link to="/service" className="Link1">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/privacy" className="Link1">
                  Privacy Policy
                </Link>{' '}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SignUp
