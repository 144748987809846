import React from 'react';
import { Box, Text, Checkbox, Flex, Stack, Progress, Icon } from '@chakra-ui/react';
import { RiEBike2Fill } from 'react-icons/ri';
import { FaFlagCheckered } from 'react-icons/fa';

function Card() {
  const value = 50; // Progress bar value
  const totalWidth = 100; // Total width of the progress bar

  const progressBarWidth = `${(value / totalWidth) * 100}%`;

  const customStyles = {
    boxShadow: "2xl", 
  };

  return (
    <Box
      flex="1"
      width={['100%', '50%', '100%']} // Responsive width based on screen sizes
      gap="20px"
      backgroundColor="#ffffff"
      boxShadow="base"
      borderRadius="10px"
      px="10px"
      py="15px"
      m="39px"
      ml="0"
      _hover={customStyles}
    >
       
      <Flex gap={['10px', '50px', '100px']}> 
        <Text fontSize={['12px', '14px']} as='b' color="#595956" >John Tae</Text> 
        <Flex gap="6px" >
          <Checkbox colorScheme="primary" defaultChecked size="sm" >
            <Text fontSize="9px" color="#ABA7A7">Pick up</Text>
          </Checkbox>
          <Checkbox colorScheme="primary" defaultChecked size="sm" >
            <Text fontSize="9px"  color="#ABA7A7">Delivered</Text>
          </Checkbox>
        </Flex>
      </Flex>

      <Flex align="center">
        <Box position="relative" flex="1" py="30px">
          <Progress value={value} w="100%" h="7px" bg="gray.200" borderRadius="md" colorScheme='primary'/>
          {value === 50 && (
            <Icon
              as={RiEBike2Fill}
              boxSize={6}
              color="#ffffff"
              bg="#1F1F1F"
              borderRadius="50%"
              padding="5px"
              position="absolute"
              top="50%"
              left={progressBarWidth}
              transform="translate(-50%, -50%)"
            />
          )}
        </Box>
        <Icon as={FaFlagCheckered} boxSize={4} color="#1F1F1F" />
      </Flex>

      <Flex gap={['20px', '80px']} w="100%" m="auto"> 
        <Box>
          <Text fontSize={['10px', '10px']} color="#ABA7A7">10:15am, Jan 10</Text> 
          <Text fontSize={['12px', '10px']} color="#595956">Oshodi Brown Street</Text> 
        </Box>
        <Box>
          <Text fontSize={['10px', '10px']} color="#ABA7A7">10:15am, Jan 10</Text> 
          <Text fontSize={['12px', '10px']} color="#595956">Oshodi Brown Street</Text> 
        </Box>
      </Flex>
    </Box>
  );
}

export default Card;
