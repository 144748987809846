
import { handleError } from "../lib/utilities";
import requestClient from "../config/axios";



// {
//   "fullName": "May",
//   "gender": "M",
//   "email": "tosiinmayowa@yahoo.com",
//   "password": "Sendrail123",
//   "phoneNumber": "08064776612",
//   "businessInfo": {
//     "name": "Reas",
//     "type": "uyttt",
//     "email": "tosiinmayowa@yahoo.com",
//     "address": "12,lud",
//     "state": "Lagos",
//     "country": "Nigeria"
//   }
// }

export interface IBusiness {
 
     name: string,
    type: string,
    email: string,
    address: string,
    state: string,
    country: string,
}

export type IData = {
 fullName: string,
  gender: string,
  email: string,
  password: string,
  phoneNumber: string,
  businessInfo:IBusiness
}

export const signUpApi = async ({
 fullName,
  gender,
  email,
  password,
  phoneNumber,
  businessInfo
}: IData) => {
  return await requestClient
    .post(`/auth/team/signup`, {
       fullName,
  gender,
  email,
  password,
  phoneNumber,
  businessInfo
    })
    .catch(handleError)
}








// import requestClient from "../config/axios";

// export interface IBusiness {
 
//   address: string,
//   state: string,
//   country: string
// }

// export type IData = {
//   name: string;
//   type: string;
//   business_address: IBusiness;
//   first_name: string;
//   last_name: string;
//   address: string;
//   state: string;
//   country: string;
//   email: string;
//   password: string;
//   role:string;
// }

// export const signUpApi = async ({
//   name,
//   type,
//   business_address,
//   first_name,
//   last_name,
//   address,
//   state,
//   country,
//   email,
//   password,role
// }: IData) => {


//        return await requestClient.post(`/auth/signup`, {
//          name,
//          type,
//          business_address,
//          first_name,
//          last_name,
//          address,
//          state,
//          country,
//          email,
//          password,
//          role
//        }).catch (error) {
//      console.log({msg:error});
     
//    }
// }