import {
    Text,
    useDisclosure,
    Image,
    Flex,Box
  } from '@chakra-ui/react'
  import React from 'react';
  import { RiCloseCircleLine } from 'react-icons/ri';
  import { FaUser } from 'react-icons/fa';
  import { AiOutlineEye } from "react-icons/ai";
  import Bus from '../../../../Asset/Rider/bus.png'
  import Bicycle from '../../../../Asset/Rider/bicycle.png'
  import Car from '../../../../Asset/Rider/car.png'
  import Bike from '../../../../Asset/Rider/bike.png'
  import Transit from '../../../../Asset/Rider/transit.png';
  import Rider from "../../../../Asset/rider.png";
  import Call from '../../../shared/Call';
  export const ShipmentHead = ({ item, phn, msg }) => {
    const { isOpen: isPhone, onOpen: openPhone, onClose: closePhone } = useDisclosure();
    return (
      <>
        {/* header wrapper*/}
        <Flex
          padding="24px 24px 30px 24px"
          width="650px"
          height="13.625rem"
          bg="#fff"
          borderRadius="20px"
          justifyContent="space-between"
          position="absolute"
          top="30px"
          left="30px">
          {/* left flex */}
          <Flex flexDir="column" justifyContent="space-between">
            <Flex width="18.875rem" height="5.5rem" justifyContent="space-between">
              <Flex width="5.0625rem" height="5.5rem" borderRadius="20px" border="1px solid #AAA">
                <Image
                  src={Rider}
                  alt="jpg"
                  objectFit="cover"
                  width="5.0625rem"
                  height="5.5rem"
                  borderRadius="20px"
                />
              </Flex>
              <Flex
                alignSelf="center"
                flexDir="column"
                width="12.9375rem"
                height="3.125rem"
                justifyContent="space-between">
                <Text flexShrink="0" color="#000" fontWeight="500" fontSize="28px" lineHeight="17px">
                  {item?.fullname}
                </Text>
                <Flex justifyContent="space-between" width="5.125rem" height="1.0625rem">
                  <Box
                    width="0.9875rem"
                    height="0.9375rem"
                    borderRadius="50%"
                    background="#079638"></Box>
                  <Text
                    // alignSelf="center"
                    flexShrink="0"
                    color="text.50"
                    fontWeight="450"
                    fontSize="20px"
                    lineHeight="17px">
                    Active
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            {/* start line bx */}
            <Flex justifyContent="space-between" width="17.75rem" height="2.8125rem">
              <Flex flexDir="column" width="5.0625rem" height="2.8125rem">
                <Image src={Transit} alignSelf="center" alt="" width="1rem" height="1rem" />
                <Box width="5.0625rem" border="3px solid #000" borderRadius="5px"></Box>
                <Text
                  alignSelf="center"
                  flexShrink="0"
                  color="#000"
                  fontWeight="450"
                  fontSize="12px"
                  lineHeight="17px"
                  textTransform="capitalize">
                  In transit
                </Text>
              </Flex>
              <Flex flexDir="column" width="5.0625rem" height="2.8125rem">
                <Image
                  src={Transit}
                  visibility="hidden"
                  alignSelf="center"
                  alt=""
                  width="1rem"
                  height="1rem"
                />
                <Box width="5.0625rem" border="3px solid #AAAAAA" borderRadius="5px"></Box>
                <Text
                  alignSelf="center"
                  flexShrink="0"
                  color="#000"
                  fontWeight="450"
                  fontSize="12px"
                  lineHeight="17px"
                  textTransform="capitalize">
                  Picked-up
                </Text>
              </Flex>
              <Flex flexDir="column" width="5.0625rem" height="2.8125rem">
                <Image
                  src={Transit}
                  visibility="hidden"
                  alignSelf="center"
                  alt=""
                  width="1rem"
                  height="1rem"
                />
                <Box width="5.0625rem" border="3px solid #AAAAAA" borderRadius="5px"></Box>
                <Text
                  alignSelf="center"
                  flexShrink="0"
                  color="#000"
                  fontWeight="450"
                  fontSize="12px"
                  lineHeight="17px"
                  textTransform="capitalize">
                  Delivered
                </Text>
              </Flex>
            </Flex>
            {/* end line bx */}
          </Flex>
          {/*end left flex */}
          {/* right flex */}
          <Flex flexDir="column" height="100%" justifyContent="space-between">
            {/* close icon */}
            <Flex alignSelf="flex-end">
              <AiOutlineEye size="40px" cursor="pointer" />
            </Flex>
            {/* end close icon */}
            <Flex
              mr="2px"
              flexDir="column"
              justifyContent="space-between"
              width="10.8375rem"
              height="7.5531rem">
              {/* courier type */}
              {item?.type === 'Bike' ? (
                <Flex
                  justifyContent="space-between"
                  alignSelf="flex-end"
                  width="3.6875rem"
                  height="1.0625rem">
                  <Image
                    src={Bike}
                    alt="Bike"
                    objectFit="contain"
                    width="16px"
                    height="9.33px"
                    alignSelf="center"
                  />
                  <Text
                    flexShrink="0"
                    color="#000"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="17px"
                    textTransform="capitalize">
                    Rider
                  </Text>
                </Flex>
              ) : item?.type === 'Car' ? (
                <Flex
                  justifyContent="space-between"
                  alignSelf="flex-end"
                  width="3.6875rem"
                  height="1.0625rem">
                  <Image
                    src={Car}
                    alt="Car"
                    objectFit="contain"
                    width="16px"
                    height="9.33px"
                    alignSelf="center"
                  />
                  <Text
                    flexShrink="0"
                    color="#000"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="17px"
                    textTransform="capitalize">
                    Driver
                  </Text>
                </Flex>
              ) : item?.type === 'Bus' ? (
                <Flex
                  justifyContent="space-between"
                  alignSelf="flex-end"
                  width="3.6875rem"
                  height="1.0625rem">
                  <Image
                    src={Bus}
                    alt="Bus"
                    objectFit="contain"
                    width="16px"
                    height="9.33px"
                    alignSelf="center"
                  />
                  <Text
                    flexShrink="0"
                    color="#000"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="17px"
                    textTransform="capitalize">
                    Driver
                  </Text>
                </Flex>
              ) : (
                <Flex
                  justifyContent="space-between"
                  alignSelf="flex-end"
                  width="3.6875rem"
                  height="1.0625rem"
                  fontSize="16px"
                  mr={5}>
                  <Text>
                    <FaUser />
                  </Text>
                  <Text
                    flexShrink="0"
                    color="#000"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="17px"
                    textTransform="capitalize">
                    Customer
                  </Text>
                </Flex>
              )}
              {/* end courier type */}
  
              {/* msg & phn icon */}
              <Flex
                justifyContent="space-between"
                border="2px solid rgba(6, 0, 137, 0.1)"
                borderRadius="20px"
                width="11.8381rem"
                height="4.4906rem"
                padding="10px 24px">
                <Image
                  alignSelf="center"
                  src={phn}
                  alt="phn"
                  width="14px"
                  height="20px"
                  objectFit="cover"
                  cursor="pointer"
                  onClick={()=>openPhone()}
                />
                <Image
                  alignSelf="center"
                  src={msg}
                  alt="msg"
                  width="20px"
                  height="20px"
                  objectFit="cover"
  
                />
              </Flex>
              <Call isOpen={isPhone} onClose={closePhone} />
              {/* end msg & phn icon */}
            </Flex>
          </Flex>
  
          {/*end right flex */}
        </Flex>
        {/*end header */}
      </>
    )
  }
  
  