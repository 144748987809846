import {
  Flex,
  Box,
  Text,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormLabel,
  Button,Center,Image
} from '@chakra-ui/react'
import Select from 'react-select'
import React,{useState,useCallback, useEffect} from 'react';
import { useAxiosPrivate } from '../../../lib/hooks/useAxiosPrivate'
import './Profile.css';
import { FiEdit2 } from "react-icons/fi";
import camera from "../../../Asset/Rider/camera.png"
import { BsGenderAmbiguous } from 'react-icons/bs';

interface GenderOptions{
  value:string,
  label:string
}

const Profile=({personalInfo})=>{
  
  
  
const {fullName, email, phoneNumber, gender, profilePicture}=personalInfo


  
const axiosPrivate = useAxiosPrivate()
const [state, setState] = useState( {
    profilePic: profilePicture,
    names: fullName,
    phoneNum: phoneNumber,
    emailD: email
  })
const [isLoading, setIsLoading] = useState(false)
 const [genders,setGender]=useState<GenderOptions|string>(gender);

const genderOptions = [
   { value: 'M', label: 'Male' },
   { value: 'F', label: 'Female' }
 ]

 const colorStyles = {
   control: (styles, { isFocused }) => {
     return {
       ...styles,
       marginTop: '0.5rem',
       height: '3rem',
       fontSize: '15px',
       lineHeight: '22px',
       background: '#fff',
       borderColor: isFocused ? '#F9C567' : '',
       color: '#ABA7A7'
     }
   }
 }
 
let {
  profilePic,names,emailD,phoneNum
}=state


const [isProf,]=useState(false);
const [isName,setIsName]=useState(false)
const [isPhn,setIsPhn]=useState(false)
const [isGender,setIsGender]=useState(false)
const [isEmail,setIsEmail]=useState(false)


const handleChange = (e) => {
   const { name, value } = e.target;

   setState({ ...state, [name]: value })
 }


 
 

const createProfile = useCallback(async () => {
   setIsLoading(true);
   const resp = await axiosPrivate.patch('/settings/me/personal-info', {
     profilePicture:profilePic,
     fullName:names,
     phoneNumber:phoneNum,
     email:emailD,
     gender:genders,
   })
   setIsLoading(false)
   console.log({responses:resp})
   if(resp){
setIsName(false);
setIsPhn(false);
setIsGender(false);
setIsEmail(false)
   }
 }, [
 profilePic,names,emailD,phoneNum,gender
 ])
useEffect(() => {
  setState(prev=>({...prev,
  profilePic: profilePicture,
    names: fullName,
    phoneNum: phoneNumber,
    emailD: email
  }))
  setGender(gender)
}, [profilePicture, fullName, phoneNumber, email, gender])

    return (
      <Center>
        <Box
          mt="56px"
          width="70%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          padding="32px 48px"
          boxSizing="border-box"
          background="rgba(7, 5, 41, 0.07)"
          borderRadius="20px">
          <Flex flexDir="column">
            <Text color="#656566" fontWeight="500" fontSize="24px" lineHeight="29px">
              Personal Information
            </Text>
            <Box className="Wrapper">
              {!isProf ? (
                <Input type="file" value={profilePicture} className="my_upload" />
              ) : (
                <Input
                  type="file"
                  value={profilePic}
                  name="profilePic"
                  onChange={handleChange}
                  className="my_upload"
                />
              )}
              <Image
                src={camera}
                alt="camera"
                position="absolute"
                top="45%"
                right="0"
                width="32px"
                height="32px"
                objectFit="cover"
              />
            </Box>
          </Flex>
          <Flex flexDir="column" width="100%">
            <Flex justifyContent="space-between" mt="70px">
              <FormControl>
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Full name
                </FormLabel>
                <InputGroup>
                  {!isName ? (
                    <Input
                      alignItems="center"
                      
                      value={fullName}
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                     
                    />
                  ) : (
                    <Input
                      alignItems="center"
                      name="names"
                      value={names}
                      width="100%"
                      height="54px"
                      background="#FFFFFF"
                      borderRadius="4px"
                      onChange={handleChange}
                    />
                  )}
                  {isName?"":<InputRightElement cursor="pointer" onClick={()=>setIsName(true)} mt="4px" children={<FiEdit2 width="16px" height="16px" />} />}
                </InputGroup>
              </FormControl>

              <FormControl ml="60px">
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Email
                </FormLabel>
                <InputGroup>
                  {!isEmail?<Input
                    alignItems="center"
                    width="100%"
                    value={email}
                    
                    height="54px"
                    background="#FFFFFF"
                    borderRadius="4px"
                   
                  />:<Input
                    alignItems="center"
                    width="100%"
                    value={emailD}
                    name="emailD"
                    height="54px"
                    background="#FFFFFF"
                    borderRadius="4px"
                    onChange={handleChange}
                  />}
                  {isEmail?"":<InputRightElement cursor="pointer" onClick={()=>setIsEmail(true)} mt="4px" children={<FiEdit2 width="16px" height="16px" />} />}
                </InputGroup>
              </FormControl>
            </Flex>

            <Flex justifyContent="space-between" mt="70px">
              <FormControl>
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Gender
                </FormLabel>
                {!isGender?<InputGroup>
                  <Input
                    alignItems="center"
                    width="100%"
                    height="54px"
                    value={gender}
                    
                    background="#FFFFFF"
                    borderRadius="4px"
                    
                  />
                  <InputRightElement cursor="pointer" onClick={()=>setIsGender(true)} mt="4px" children={<FiEdit2 width="16px" height="16px" />} />
                </InputGroup>:<Select
                  value={genders}
                  options={genderOptions}
                  onChange={(genders) => setGender(genders)}
                  styles={colorStyles}
                />}
              </FormControl>

              <FormControl ml="60px">
                <FormLabel color=" #ABA7A7" fontWeight="500" fontSize="18px" lineHeight="22px">
                  Phone number
                </FormLabel>
                <InputGroup>
                  {!isPhn?<Input
                    alignItems="center"
                    width="100%"
                    height="54px"
                    value={phoneNumber}
                   
                    background="#FFFFFF"
                    borderRadius="4px"
                   
                  />:<Input
                    alignItems="center"
                    width="100%"
                    height="54px"
                    value={phoneNum}
                    name="phoneNum"
                    background="#FFFFFF"
                    borderRadius="4px"
                    onChange={handleChange}
                  />}
                  {!isPhn?<InputRightElement cursor="pointer" onClick={()=>setIsPhn(true)} mt="4px" children={<FiEdit2 width="16px" height="16px" />} />:''}
                </InputGroup>
              </FormControl>
            </Flex>

            <Button
              padding="16px 32px"
              width="168px"
              height="54px"
              background="rgba(7, 5, 41, 0.9)"
              borderRadius="8px"
              fontWeight="500"
              fontSize="16px"
              lineHeight="22px"
              color="#FFFFFF"
              mt="48px"
              onClick={createProfile}>
              Save Changes
            </Button>
          </Flex>
        </Box>
      </Center>
    )
}

export default Profile;