import React, { useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormLabel,
  Image,
  PinInputField,
  PinInput,
  HStack,
  Button
} from '@chakra-ui/react'
import { CustomBox } from './CustomBox'
import ChangePin from "./ChangePin";
import ResetPin from './ResetPin';
export const TransactionSettings = ({ setIsTransaction, setSecurity, setIsLogin }) => {
  const trans=true
  const [isChangePin,setChangePin]=useState(false)
    const [isForgotPin,setForgotPin]=useState(false)
    const [isTrans,setIsTrans] = useState(true)
    return (
      <>
        <Flex width="100%" height="100vh" justifyContent="center">
          {isTrans ? (
            <Flex
              flexDir="column"
              width="36rem"
              height="29.25rem"
              padding="2rem 3rem 5.4375rem"
              borderRadius="20px"
              justifyContent="space-between"
              background="rgba(7, 5, 41, 0.07)">
              {/* Inner flex */}
              <Flex flexDir="column" justifyContent="space-between" width="36rem" height="29.25rem">
                <Text fontWeight="500" fontSize="24px" lineHeight="29px" color="#656566">
                  Transaction settings
                </Text>
                <Flex
                  width="30rem"
                  height="21.4375rem"
                  flexDir="column"
                  justifyContent="space-between">
                  {/* pin flex */}
                  <Flex
                    width="29.75rem"
                    height="6.5625rem"
                    flexDir="column"
                    justifyContent="space-between">
                    <Text fontWeight="600" fontSize="16px" lineHeight="22px" color="text.100">
                      Create Transaction Pin
                    </Text>
                    <HStack spacing="56px">
                      <PinInput focusBorderColor="black" placeholder="" size="lg">
                        <PinInputField background="#fff" />
                        <PinInputField background="#fff" />
                        <PinInputField background="#fff" />
                        <PinInputField background="#fff" />
                        <PinInputField background="#fff" />
                      </PinInput>
                    </HStack>
                  </Flex>
                  {/* end pin flex */}
                  {/*  custmbox*/}
                  <Flex
                    width="100%"
                    height="12.875rem"
                    flexDirection="column"
                    justifyContent="space-between">
                    <Button
                      padding="10px 32px"
                      borderRadius="4px"
                      width="7.8125rem"
                      height="2.625rem"
                      background="#33324b"
                      fontWeight="500"
                      fontSize="16px"
                      color="#fff"
                      lineHeight="22px">
                      Confirm
                    </Button>
                    <CustomBox
                      flexWidth="30rem"
                      flexHeight="3.375rem"
                      wrapperHeight="8.25rem"
                      Text1="Change pin"
                      Text2="Forgot pin"
                      color="#AAAAAA"
                      trans={trans}
                      setChangePin={setChangePin}
                      setIsTrans={setIsTrans}
                    />
                  </Flex>
                  {/* end custm box */}
                </Flex>
              </Flex>
              {/* Inner flex */}
            </Flex>
          ) : isChangePin ? (
            <ChangePin />
          ) : (
            <ResetPin />
          )}
        </Flex>
      </>
    )
}
