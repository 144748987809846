import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Box,
  Text,
  Flex,
  Image,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  ListIcon,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import CardImg from '../../../../Asset/Transactions/cardImg.png'
import { AiFillEye } from 'react-icons/ai'
import { TbCurrencyNaira } from 'react-icons/tb'
import { BiCopy } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { MdArrowForward } from 'react-icons/md'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { PiSmileySad } from 'react-icons/pi'
import { bankList } from '../../../../api/bankList'



const AddCard = () => {
  const toast = useToast()
  const linkRef = useRef(null)
  const pinInputsRef = useRef([])
  const [showNewCard, setShowNewCard] = useState(false)
  const [isCardVisible, setIsCardVisible] = useState(false)
  const [successCard, setSuccessCard] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true) // Add state for submit button disable/enable
  const [pin, setPin] = useState(['', '', '', ''])
  const [banks, setBanks] = useState([])
  const [bankName, setBankName] = useState('')
  const [bankSuggestions, setBankSuggestions] = useState([])

  const getBankList = useCallback(async () => {
    const resp = await bankList()
    console.log({ bankList: resp })
    if(resp){
      setBanks(resp?.data)
    }
  }, []);

  useEffect(() => {
    getBankList()
  }, [])

  

  const handleCopyLink = () => {
    if (linkRef.current) {
      linkRef.current.select()
      document.execCommand('copy')
      toast({
        title: 'Link Copied',
        status: 'success',
        duration: 2000,
        isClosable: true
      })
    }
  }

  const handleCopyAccount = () => {
    const accountNumber = document.getElementById('accountNumber').innerText
    if (accountNumber) {
      navigator.clipboard.writeText(accountNumber)
      toast({
        title: 'Account Number Copied',
        status: 'success',
        duration: 2000,
        isClosable: true
      })
    }
  }

  

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    setBankName(inputValue)

    // Filter bank suggestions based on user input
    const filteredBanks = banks.filter((bank) =>
      bank.bankName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setBankSuggestions(filteredBanks)
  }

  const handleSuggestionClick = (suggestion) => {
    setBankName(suggestion)
    setBankSuggestions([])
  }

  const shouldShowBankSuggestions = bankName.length > 0 && bankSuggestions.length > 0

  // Check if all inputs are filled
  useEffect(() => {
    const areInputsFilled = bankName && bankName.trim().length > 0
    setSubmitDisabled(!areInputsFilled)
  }, [bankName])

  const handlePinChange = (index, value) => {
    const newPin = [...pin]
    newPin[index] = value
    setPin(newPin)
  }

  const {
    isOpen: isFirstModalOpen,
    onOpen: openFirstModal,
    onClose: closeFirstModal
  } = useDisclosure()
  const {
    isOpen: isSecondModalOpen,
    onOpen: openSecondModal,
    onClose: closeSecondModal
  } = useDisclosure()
  const {
    isOpen: isThirdModalOpen,
    onOpen: openThirdModal,
    onClose: closeThirdModal
  } = useDisclosure()
  const {
    isOpen: isFourthModalOpen,
    onOpen: openFourthModal,
    onClose: closeFourthModal
  } = useDisclosure()

  const openSecondModalFromFirstModal = () => {
    closeFirstModal()
    openSecondModal()
  }

  const openThirdModalFromSecondModal = () => {
    closeFirstModal()
    closeSecondModal()
    openThirdModal()
  }
  const openFourthModalFromSecondModal = () => {
    closeFirstModal()
    closeSecondModal()
    openFourthModal()
  }

  const handleBackButtonClick = () => {
    closeFourthModal()
    openSecondModal()
  }

  const handleRetryButtonClick = () => {
    closeFourthModal()
    openFirstModal()
  }
  const handleContinueButtonClick = () => {
    closeThirdModal() // Close the third modal
  }

  // const [pin, setPin] = useState('')

  const handlePinSubmit = () => {
    const enteredPin = pin.join('') // Convert the pin array to a string

    if (enteredPin === '1234') {
      closeSecondModal()
      openThirdModal()
      setPin(['', '', '', ''])
    } else {
      closeSecondModal()
      openFourthModal()
      setPin(['', '', '', ''])
    }
  }

  const handlePinKeyDown = (index, event) => {
    const value = event.target.value
    const newPin = [...pin]
    newPin[index] = value

    setPin(newPin)

    if (value && index < pin.length - 1) {
      // Move focus to the next input
      const nextInput = pinInputsRef.current[index + 1]
      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  return (
    <Flex gap="50px" align="center" mt="48px" ml="20px">
      <Box position="relative">
        <Image src={CardImg} alt="" />
        <Box
          position="absolute"
          top="50%"
          left="48%"
          right="-25%"
          transform="translate(-50%, -50%)"
          color="#ffffff">
          <Flex align="center" fontSize="18px" gap={2}>
            <Text color="#F9C567">Account Balance</Text>
            <AiFillEye />
          </Flex>
          <Flex align="center" fontSize="20px" color="#ffffff">
            <TbCurrencyNaira />
            <Text>0.00</Text>
          </Flex>
          <Flex align="center" gap={2} color="#ffffff" justifyItems="space-between">
            <Text fontSize="18px" fontWeight="bold">
              KUDA Bank
            </Text>
            <Text id="accountNumber" fontSize="16px">
              3049904069
            </Text>
            <BiCopy color="#F9C567" fontSize="25px" onClick={handleCopyAccount} />
          </Flex>
          <Flex color="#070529" justify="space-between" opacity={1} mt="20px">
            <Button backgroundColor="rgba(249, 197, 103, 0.90)" size="sm">
              Deposit
            </Button>
            <Button backgroundColor="rgba(249, 197, 103, 0.90)" size="sm" onClick={openFirstModal}>
              Transfer
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box m="20px" backgroundColor="#ffffff" boxShadow="base" p="15px" borderRadius="10px">
        <Text color="#000000" as="b">
          Share Link
        </Text>
        <Text fontSize="13px" color="#595956">
          Share your embedded pick up and delivery link for customers to be able to onboard directly
          from you.
        </Text>
        <Flex
          marginTop={4}
          justify="center"
          alignItems="center"
          backgroundColor="#07052912"
          color="#F9C567"
          cursor="pointer">
          <input
            ref={linkRef}
            type="text"
            readOnly
            value="https://onboard.sendrail.com/dummyco"
            style={{
              width: '50%',
              border: 'none',
              outline: 'none',
              backgroundColor: 'transparent',
              color: 'inherit'
            }}
          />
        </Flex>
        <Box display="flex" gap="4px" marginTop={4}>
          <Button
            backgroundColor="rgba(7, 5, 41, 1)"
            size="sm"
            variant="solid"
            color="#ffffff"
            onClick={handleCopyLink}>
            Copy
          </Button>
        </Box>
      </Box>

      <Modal isOpen={isFirstModalOpen} onClose={closeFirstModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="center" color="#000000" as="b">
              <Text>Transfer</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box display="flex" alignItems="center" py="30px">
                <Box flex="1" height="1px" bg="gray.300" />
                <Box borderRadius="full" bg="gray.300" mx="2" />
                <Box borderRadius="full" bg="gray.300" />
              </Box>
              <FormControl isRequired>
                <FormLabel>Bank Name</FormLabel>
                <Input
                  type="text"
                  value={bankName}
                  onChange={handleInputChange}
                  placeholder="Enter a bank"
                />
                {shouldShowBankSuggestions && (
                  <List mt={2} maxHeight="120px" overflowY="auto" bg="white" boxShadow="sm" p={2}>
                    {bankSuggestions.map((suggestion) => (
                      <ListItem
                        key={suggestion}
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => handleSuggestionClick(suggestion)}>
                        <ListIcon as={MdArrowForward} color="gray.500" />
                        {suggestion}
                      </ListItem>
                    ))}
                  </List>
                )}
                <FormLabel>Account Number</FormLabel>
                <Input placeholder="Enter an account number" />
                <FormLabel>Amount</FormLabel>
                <Input placeholder="Enter amount" />
              </FormControl>
            </Box>
          </ModalBody>
          <Flex justify="center" py="50px">
            <Button
              _hover={{}}
              fontSize="16px"
              type="submit"
              backgroundColor={submitDisabled ? 'rgba(6, 0, 137, 0.15)' : '#070529'}
              w="60%"
              color="#ffffff"
              disabled={submitDisabled}
              onClick={openSecondModalFromFirstModal}>
              Next
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSecondModalOpen} onClose={closeSecondModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="center" color="#000000" as="b">
              <Text>Transfer</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box display="flex" alignItems="center" py="30px">
                <Box flex="1" height="1px" bg="gray.300" />
                <Box borderRadius="full" bg="gray.300" mx="2" />
                <Box borderRadius="full" bg="gray.300" />
              </Box>

              <FormControl>
                <FormLabel width="80%" m="auto" pb="40px">
                  Enter Your Transaction Pin To Complete Transfer
                </FormLabel>
                <Flex width="70%" m="auto">
                  <HStack spacing="4">
                    {pin.map((digit, index) => (
                      <Input
                        key={index}
                        type="password"
                        // maxLength="1"
                        textAlign="center"
                        width="40px"
                        height="40px"
                        fontSize="20px"
                        m="10px"
                        borderColor="gray.300"
                        borderRadius="5px"
                        value={digit}
                        ref={(el) => (pinInputsRef.current[index] = el)}
                        onChange={(event) => handlePinChange(index, event.target.value)}
                        onKeyDown={(event) => handlePinKeyDown(index, event)}
                      />
                    ))}
                  </HStack>
                </Flex>
              </FormControl>
            </Box>
          </ModalBody>
          <Flex justify="center" py="50px">
            <Button
              _hover={{}}
              fontSize="16px"
              type="submit"
              backgroundColor={submitDisabled ? 'rgba(6, 0, 137, 0.15)' : '#070529'}
              w="60%"
              color="#ffffff"
              disabled={submitDisabled}
              onClick={handlePinSubmit}>
              Confirm
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      <Modal isOpen={isThirdModalOpen} onClose={closeThirdModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Flex align="center" justifyContent="center" textAlign="center">
                <Box>
                  <Flex justify="center" fontSize="50px" color="#20AE5C" p="5px">
                    <IoMdCheckmarkCircleOutline />
                  </Flex>
                  <Text color="#20AE5C" fontSize="22px" p="10px">
                    Success
                  </Text>

                  <Text color="#4A4A4A" fontSize="14px" as="b">
                    Thank you for your request. We are working hard to find the best services for
                    you.
                  </Text>
                  <Text color="#4A4A4A" fontSize="14px" pb="20px">
                    Transfer Successful
                  </Text>
                </Box>
              </Flex>
            </Box>
          </ModalBody>
          <Flex justify="center" p="20px">
            <Button
              cursor="pointer"
              onClick={handleContinueButtonClick}
              _hover={{}}
              fontSize="16px"
              type="submit"
              backgroundColor="#070529"
              w="60%"
              color="#ffffff"
              disabled={submitDisabled}>
              Continue
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      <Modal isOpen={isFourthModalOpen} onClose={closeFourthModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex align="center" justifyContent="center" textAlign="center" py="30px">
              <Box>
                <Box color="#F32E2E">
                  <Flex justify="center" fontSize="50px" pb="10px">
                    <PiSmileySad />
                  </Flex>

                  <Text fontSize="20px" as="b">
                    ERROR
                  </Text>
                </Box>

                <Box pt="20px">
                  <Text color="#4A4A4A" fontSize="14px" as="b">
                    We are unable to complete your transfer request.
                  </Text>
                  <Text color="#4A4A4A" fontSize="14px" pb="20px">
                    Please try again later
                  </Text>
                </Box>

                <Flex justify="space-around">
                  <Button
                    backgroundColor="#ffffff"
                    color="#333344"
                    variant="outline"
                    mr={3}
                    disabled={submitDisabled}
                    onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <Button
                    background="rgba(7, 5, 41, 0.90)"
                    color="#ffffff"
                    variant="solid"
                    mr={3}
                    disabled={submitDisabled}
                    onClick={handleRetryButtonClick}>
                    Retry
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default AddCard
