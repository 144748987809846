import React, { useState } from 'react';
import { Box, Text, Flex, Stack, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { BsBoxSeamFill } from 'react-icons/bs';
import { TbTruckDelivery } from 'react-icons/tb';
import { MdOutlineLockClock, MdKeyboardArrowDown } from 'react-icons/md';
import { FaTimes, FaBusinessTime } from 'react-icons/fa';
import '../Dashboard/styles/scrollBar.css';

function Slider() {
  const options = ['This Month', 'Next Month', 'Previous Month'];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const customStyles = {
    boxShadow: 'xl',
    
  };

  const menuItemStyles = {
    _hover: { backgroundColor: '#070529', color: 'white' }, // Apply hover color only to the dropdown items
  };

  return (
    <div>
      <Box py="20px" px="15px">
        <Text fontSize="24px" as="b">
          Dashboard Overview
        </Text>
      </Box>
      <Box className="chakra-wrapper" overflow="auto" overflowX="scroll" width="95%" m="auto">
        <Box display="flex" gap={4} width={1300}>
          <Stack
            backgroundColor="white"
            width="311px"
            borderRadius="10px"
            boxShadow="base"
            my="20px"
            mx="2px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            cursor="pointer"
            _hover={customStyles}
          >
            <Flex justifyContent="space-between" alignSelf="flex-end" mr="15px" mt="15px">
            <Menu>
              
              <Flex align='center'>
              <MenuButton
                as={Box}
                width="130px"
                bg="white"
                border="none"
                outline="none"
                alignSelf="flex-end"
                textAlign="right"
              >
                {selectedOption}
                </MenuButton>
                <MdKeyboardArrowDown />
              </Flex>
              
             
              <MenuList>
                {options.map((option) => (
                  <MenuItem key={option} onClick={() => handleOptionChange(option)} _hover={menuItemStyles._hover}>
                    {option}
                  </MenuItem>
                ))}
              </MenuList>

            </Menu>
            </Flex>
            <Flex fontWeight="bold" align="center" gap="15px" pb="20px">
              <Box fontSize="30px" color="#FFFFFF" backgroundColor="#070529" p="10px" marginLeft="20px" borderRadius="50%">
                <BsBoxSeamFill />
              </Box>
              <Box>
                <Text fontSize="20px">Total orders</Text>
                <Flex align="center" gap={3}>
                  <Text fontSize="24px">0</Text>
                  <Flex align="center" gap={1}>
                    <AiOutlineArrowUp color="#079638" />
                    <Text color="#079638" fontSize="14px">
                      0.0%
                    </Text>
                    <Text color="#ABA7A7" fontSize="14px">
                      than last week
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Stack>

          <Stack
            backgroundColor="white"
            width="311px"
            borderRadius="10px"
            boxShadow="base"
            my="20px"
            mx="2px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            cursor="pointer"
            _hover={customStyles}
          >
            <Flex justifyContent="space-between" alignSelf="flex-end" mr="15px" mt="15px">
            <Menu>
              
              <Flex align='center'>
              <MenuButton
                as={Box}
                width="130px"
                bg="white"
                border="none"
                outline="none"
                alignSelf="flex-end"
                textAlign="right"
              >
                {selectedOption}
                </MenuButton>
                <MdKeyboardArrowDown />
              </Flex>
              
             
              <MenuList>
                {options.map((option) => (
                  <MenuItem key={option} onClick={() => handleOptionChange(option)} _hover={menuItemStyles._hover}>
                    {option}
                  </MenuItem>
                ))}
              </MenuList>

            </Menu>
            </Flex>
            <Flex fontWeight="bold" align="center" gap="15px" pb="20px">
              <Box fontSize="30px" color="#FFFFFF" backgroundColor="#070529" p="10px" marginLeft="20px" borderRadius="50%">
                <TbTruckDelivery />
              </Box>
              <Box>
                <Text fontSize="20px">In transit</Text>
                <Flex align="center" gap={3}>
                  <Text fontSize="24px">0</Text>
                  <Flex align="center" gap={1}>
                    <AiOutlineArrowDown color="#CD0B3A" />
                    <Text color="#CD0B3A" fontSize="14px">
                      0.0%
                    </Text>
                    <Text color="#ABA7A7" fontSize="14px">
                      than last week
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Stack>

          <Stack
            backgroundColor="white"
            width="311px"
            borderRadius="10px"
            boxShadow="base"
            my="20px"
            mx="2px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            cursor="pointer"
            _hover={customStyles}
          >
            <Flex justifyContent="space-between" alignSelf="flex-end" mr="15px" mt="15px">
            <Menu>
              
              <Flex align='center'>
              <MenuButton
                as={Box}
                width="130px"
                bg="white"
                border="none"
                outline="none"
                alignSelf="flex-end"
                textAlign="right"
              >
                {selectedOption}
                </MenuButton>
                <MdKeyboardArrowDown />
              </Flex>
              
             
              <MenuList>
                {options.map((option) => (
                  <MenuItem key={option} onClick={() => handleOptionChange(option)} _hover={menuItemStyles._hover}>
                    {option}
                  </MenuItem>
                ))}
              </MenuList>

            </Menu>
            </Flex>
            <Flex fontWeight="bold" align="center" gap="15px" pb="20px">
              <Box fontSize="30px" color="#FFFFFF" backgroundColor="#070529" p="10px" marginLeft="20px" borderRadius="50%">
                <FaBusinessTime />
              </Box>
              <Box>
                <Text fontSize="20px">Pending</Text>
                <Flex align="center" gap={3}>
                  <Text fontSize="24px">0</Text>
                  <Flex align="center" gap={1}>
                    <AiOutlineArrowUp color="#079638" />
                    <Text color="#079638" fontSize="14px">
                      0.0%
                    </Text>
                    <Text color="#ABA7A7" fontSize="14px">
                      than last week
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Stack>

          <Stack
            backgroundColor="white"
            width="311px"
            borderRadius="10px"
            boxShadow="base"
            my="20px"
            mx="2px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            cursor="pointer"
            _hover={customStyles}
          >
            <Flex justifyContent="space-between" alignSelf="flex-end" mr="15px" mt="15px">
            <Menu>
              
              <Flex align='center'>
              <MenuButton
                as={Box}
                width="130px"
                bg="white"
                border="none"
                outline="none"
                alignSelf="flex-end"
                textAlign="right"
              >
                {selectedOption}
                </MenuButton>
                <MdKeyboardArrowDown />
              </Flex>
              
             
              <MenuList>
                {options.map((option) => (
                  <MenuItem key={option} onClick={() => handleOptionChange(option)} _hover={menuItemStyles._hover}>
                    {option}
                  </MenuItem>
                ))}
              </MenuList>

            </Menu>
            </Flex>
            <Flex fontWeight="bold" align="center" gap="15px" pb="20px">
              <Box fontSize="30px" color="#FFFFFF" backgroundColor="#070529" p="10px" marginLeft="20px" borderRadius="50%">
                <FaTimes />
              </Box>
              <Box>
                <Text fontSize="20px">Cancelled Orders</Text>
                <Flex align="center" gap={3}>
                  <Text fontSize="24px">0</Text>
                  <Flex align="center" gap={1}>
                    <AiOutlineArrowDown color="#CD0B3A" />
                    <Text color="#CD0B3A" fontSize="14px">
                      0.0%
                    </Text>
                    <Text color="#ABA7A7" fontSize="14px">
                      than last week
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Stack>

         
        </Box>
      </Box>
    </div>
  );
}

export default Slider;
