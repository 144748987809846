import React, { useEffect,useCallback,useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../lib/hooks/useAuth";
import { useAxiosPrivate } from '../lib/hooks/useAxiosPrivate'
import jwt_decode from 'jwt-decode'

function ProtectAuth() {
     const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [user,setUser]=useState(null);
    const location = useLocation();

    const token = localStorage.getItem("token");
    if(!token){
      return <Navigate to="/login" state={{ from: location }} replace />
    }
    const decodedToken : any =  jwt_decode(token) 
    
    if (token && decodedToken && decodedToken.exp < (Date.now())) {
      return <Outlet />
    }
    return <Navigate to="/login" state={{ from: location }} replace />
}

export default ProtectAuth;