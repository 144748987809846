import React,{useState} from 'react';
import {
  Flex,
  Box,
  Text,
  Image,
 
} from '@chakra-ui/react'

import {TransactionSettings} from './TransactionSettings'
import { CustomBox } from './CustomBox';
import LoginSettings from './LoginSettings';
const Security = () => {
  const [isSecurity,setSecurity]=useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [isTransaction, setIsTransaction] = useState<boolean>(false)
const security=true
    
      return (
        <>
          {' '}
          <Flex width="100%" height="100vh" justifyContent="center">
            {isSecurity ? (
              <Flex
                flexDir="column"
                width="36.375rem"
                height="25.3125rem"
                padding="32px 75px 128px 48px"
                borderRadius="20px"
                justifyContent="space-between"
                background="rgba(7, 5, 41, 0.07)">
                <Text fontWeight="500" fontSize="24px" lineHeight="22px">
                  Security Information
                </Text>
                <CustomBox
                  flexWidth="28.6875rem"
                  flexHeight="4.5rem"
                  wrapperHeight="11rem"
                  Text1="Login settings"
                  Text2="Transaction settings"
                  color="text.50"
                  setIsTransaction={setIsTransaction}
                  setSecurity={setSecurity}
                  setIsLogin={setIsLogin}
                  security={security}
                />
              </Flex>
            ) : isLogin ? (
              <LoginSettings />
            ) : (
              <TransactionSettings
                setIsTransaction={setIsTransaction}
                setSecurity={setSecurity}
                setIsLogin={setIsLogin}
              />
            )}
          </Flex>
        </>
      )
    
    
    

    
    
  
}

export default Security;