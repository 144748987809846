import React, { useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Input,
  InputRightElement,
  InputGroup,
  FormControl,
  FormLabel,
  Image,
  PinInputField,
  PinInput,
  HStack,
  Button
} from '@chakra-ui/react'

export const ChangeTwoFA = () => {
  return (
    <>
      <Flex
        flexDir="column"
        width="694px"
        height="425px"
        padding="48px 120px"
        borderRadius="20px"
        justifyContent="space-between"
        background="rgba(7, 5, 41, 0.07)">
        <Flex
          width="454px"
          height="251px"
          padding="32px"
          flexDir="column"
          justifyContent="space-between">
          {/* pin main wrapper */}
          <Flex width="390px" height="120px" flexDir="column" justifyContent="space-between">
            {/* text flex */}
            <Flex width="314px" height="44px" flexDir="column" justifyContent="space-between">
              <Text fontWeight="500" fontSize="18px" lineHeight="22px" color="#1F1F1F">
                Change Pin
              </Text>
              <Text fontWeight="450" fontSize="12px" lineHeight="14px" color="#656566">
                enter the 2Factor Authentication code sent to your email
              </Text>
            </Flex>
            {/* end text flex */}

            {/* pin input */}
            <HStack spacing="24px">
              <PinInput focusBorderColor="black" placeholder="" size="md">
                <PinInputField background="#fff" />
                <PinInputField background="#fff" />
                <PinInputField background="#fff" />
                <PinInputField background="#fff" />
                <PinInputField background="#fff" />
                <PinInputField background="#fff" />
              </PinInput>
            </HStack>
            {/* end pin input */}
          </Flex>
          {/*end  pin main wrapper */}
          {/* Resend flex */}
          <Flex width="225px" height="22px" alignSelf="center" justifyContent="space-between">
            <Text fontWeight="500" fontSize="16px" lineHeight="22px" color="#000">
              Did not receive code?
            </Text>
            <Button
              variant="link"
              fontWeight="600"
              fontSize="16px"
              color="primary.100"
              lineHeight="22px"
              _hover={{
                color: 'primary.100'
              }}>
              Resend
            </Button>
          </Flex>
          {/* End Resend flex */}
        </Flex>
        <Button
          alignSelf="flex-end"
          padding="8px 24px"
          borderRadius="4px"
          width="79px"
          height="38px"
          background="#33324b"
          fontWeight="500"
          fontSize="14px"
          color="#fff"
          // onClick={() => setIsNew(true)}
          lineHeight="22px">
          Next
        </Button>
      </Flex>
    </>
  )
}
