import React, { useCallback, useState } from 'react'
import '../Style/signUp.css'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  PinInputField,
  PinInput,
  HStack,
  useMediaQuery,
  Flex,
  Box,
  Image,
  Text,
  Button
} from '@chakra-ui/react'
import Logo from '../Asset/Onbarding/SENDRAILS.png'
import { verify } from '../api/verify'
import { SideBarOnboard } from '../Component/SideBarOnbard/SideBarOnboard'
import { FormHead } from '../Component/FormHead/FormHead'
import { useAxiosPrivate } from '../lib/hooks/useAxiosPrivate'

function Verification() {
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)')
  const [isSmallerThan740] = useMediaQuery('(max-width: 740px)')
  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
  const [isSmallerThan530] = useMediaQuery('(max-width: 530px)')
  const axiosPrivate = useAxiosPrivate()
 const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = useState('')

  const reset = useCallback(() => setValue(''), [])
  const handleChange = (value: string) => {
    setValue(value)
  }

  const verify = useCallback(async () => {
   setIsLoading(true)
    const resp = await axiosPrivate.post('/auth/otp/verify', {
      type: "ACCOUNT_EMAIL_VERIFY",
      code: value
    })
    setIsLoading(false)
    if(resp){
      navigate('/login')
    }
  }, [value, location])
  // const sendVerification = useCallback(async () => {
  //

  //   const resp = await verify({
  //     email,
  //     confirm_email_token: value
  //   })

  //   console.log(resp)

  //   // if (resp?.data?.success) {
  //   //   navigate('/login')
  //   // }
  //   // setErrState(resp?.data?.success)
  // }, [value, location, navigate])
  return (
    <Flex width="100%" height="76.5rem">
      <SideBarOnboard
        isSmallerThan530={isSmallerThan530}
        isSmallerThan1024={isSmallerThan1024}
        isSmallerThan900={isSmallerThan900}
        isSmallerThan740={isSmallerThan740}
      />
      <Box
        display="flex"
        flexDir="column"
        bg="#fff"
        width="61.8%"
        padding="93px 64px 64px"
        alignItems="flex-start">
        <Flex alignSelf="center">
          <Image src={Logo} alt="Sendrail" width="30px" height="22px" objectFit="cover" />
        </Flex>
        <Flex
          flexDir="column"
          width={isSmallerThan740 ? '25rem' : isSmallerThan1024 ? '30rem' : '35.5rem'}
          padding={
            isSmallerThan740
              ? '8px 25px 25px 25px'
              : isSmallerThan1024
              ? '13px 32px 32px 32px'
              : '13px 32px 32px 32px'
          }
          alignSelf="center">
          <Flex
            alignSelf="center"
            flexDir="column"
            width={isSmallerThan740 ? '18rem' : isSmallerThan1024 ? '20rem' : '23.375rem'}
            height={isSmallerThan740 ? '7rem' : isSmallerThan1024 ? '8rem' : '8.5rem'}
            justifyContent="space-between"
            alignItems="flex-start">
            <Flex
              flexDir="column"
              width={isSmallerThan740 ? '18rem' : isSmallerThan1024 ? '20rem' : '23.375rem'}
              height={isSmallerThan740 ? '2rem' : isSmallerThan1024 ? '3rem' : '3.75rem'}
              justifyContent="space-between"
              alignItems="center">
              <Text
                flexShrink="0"
                fontWeight="600"
                color="#1F1F1F"
                fontSize={
                  isSmallerThan530
                    ? '12px'
                    : isSmallerThan740
                    ? '14px'
                    : isSmallerThan1024
                    ? '18px'
                    : '24px'
                }
                lineHeight={
                  isSmallerThan530
                    ? '9px'
                    : isSmallerThan740
                    ? '12px'
                    : isSmallerThan1024
                    ? '15px'
                    : '22px'
                }>
                Verification
              </Text>
              <Text
                flexShrink="0"
                fontWeight="600"
                color="#ABA7A7"
                fontSize={
                  isSmallerThan530
                    ? '9px'
                    : isSmallerThan740
                    ? '12px'
                    : isSmallerThan1024
                    ? '14px'
                    : '16px'
                }
                lineHeight={isSmallerThan740 ? '10px' : isSmallerThan1024 ? '12px' : '14px'}>
                enter the verification code sent to your email
              </Text>
            </Flex>
            <HStack alignSelf="center">
              <PinInput
                placeholder=""
                value={value}
                onChange={handleChange}
                focusBorderColor="primary.main">
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Flex>

          <Button
            mt="45px"
            alignSelf={isSmallerThan740 ? 'center' : isSmallerThan1024 ? 'center' : 'flex-start'}
            padding={
              isSmallerThan740 ? '0.5rem 1.5rem' : isSmallerThan1024 ? '1rem 2rem' : '1rem 2rem'
            }
            width={
              isSmallerThan530
                ? '15rem'
                : isSmallerThan740
                ? '20rem'
                : isSmallerThan1024
                ? '25rem'
                : '31.5rem'
            }
            height="3.5rem"
            background="primary.main"
            borderRadius="4px"
            fontWeight="500"
            fontSize="18px"
            lineHeight="22px"
            color="#fff"
            textAlign="center"
            isLoading={isLoading ? true : false}
            loadingText="Loading"
            spinnerPlacement="start"
            variant="filled"
            onClick={() => {
              verify()
              reset()
            }}
            _hover={{
              background: 'primary.200'
            }}
            isDisabled={!value}>
            Next
          </Button>

          <Flex alignSelf="center" mt={['30px', '40px', '45px']}>
            <Text flexShrink="0" fontWeight="500" color="#000000" fontSize="16px" lineHeight="22px">
              Did not receive OTP?
            </Text>
            <Button
              variant="link"
              ml="10px"
              fontWeight="600"
              color="#F9C567"
              fontSize="16px"
              lineHeight="22px"
              _hover={{
                background: 'primary.300',
                textDecration: 'none'
              }}>
              Resend
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>

    // <Flex flexDir="column" width="100%" height="100vh" background={theme.colors.primary['100']}>
    //   <Flex mt="60px" ml="60px">
    //     <Image src={Logo} alt="Logo" />
    //     <Text
    //       ml="4px"
    //       fontWeight="500"
    //       fontSize="24px"
    //       lineHeight="29px"
    //       display="flex"
    //       alignItems="center"
    //       textAlign="right"
    //       color={theme.colors.primary.main}>
    //       Sendrail
    //     </Text>
    //   </Flex>
    //   <Center>
    //     <Box
    //       mt="40px"
    //       display="flex"
    //       flexDirection="column"
    //       padding="38px 40px"
    //       width="500px"
    //       height="400px"
    //       bg="#FFFFFF"
    //       borderRadius="12px">
    //       <Box>
    //         <Box width="400px">
    //           <Center>
    //             <Box width="100%" mb="30px">
    //               <Center>
    //                 <Image src={Logo} alt="Onboarding Logo" pr="10px" />
    //               </Center>
    //               <Box mt="13px" width="100%" textAlign="center">
    //                 <Text fontWeight="600" fontSize="24px" lineHeight="22px">
    //                   Verification
    //                 </Text>
    //               </Box>
    //             </Box>
    //           </Center>
    //           <Box
    //             width="100%"
    //             fontWeight="500"
    //             fontSize="14px"
    //             lineHeight="22px"
    //             mt="24px"
    //             textAlign="center"
    //             color="#ABA7A7">
    //             <Text>Enter The Verification Code Sent To Your Email</Text>
    //           </Box>
    //         </Box>
    //         <FormControl>
    //           <Input
    //             placeholder="Enter verification code"
    //             mt="30px"
    //             ml="15px"
    //             value={code}
    //             onChange={(e) => setCode(e.target.value)}
    //             padding="10px 12px 10px 10px"
    //             width="400px"
    //             height="44px"
    //             background="#fff"
    //             border="1px solid #ABA7A7"
    //             borderRadius="4px"
    //             fontWeight="500"
    //             fontSize="16px"
    //             lineHeight="22px"
    //             color="#1F1F1F"
    //             focusBorderColor={theme.colors.primary.main}
    //           />
    //           <FormHelperText
    //             fontWeight="500"
    //             fontSize="14px"
    //             lineHeight="22px"
    //             ml="15px"
    //             color='#FF0000'>
    //             {!errState ? 'invalid or expired code' : ''}
    //           </FormHelperText>

    //           <Button
    //             mt="70px"
    //             ml="15px"
    //             padding="16px 32px"
    //             width="400px"
    //             height="48px"
    //             background={theme.colors.primary.main}
    //             borderRadius="4px"
    //             fontWeight="500"
    //             fontSize="18px"
    //             lineHeight="22px"
    //             color="#fff"
    //             textAlign="center"
    //             onClick={sendVerification}
    //             _hover={{
    //               background: '#16134f'
    //             }}
    //             isDisabled={!code}>
    //             Next
    //           </Button>
    //         </FormControl>
    //       </Box>
    //     </Box>
    //   </Center>
    // </Flex>
  )
}

export default Verification
