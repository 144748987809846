import React, { useState } from "react";
import {
    Box,
    Image,
    Button
} from "@chakra-ui/react";
import * as images from "../../../../images";
import DashboardLayout from "../../DashboardLayout";
import { ShipmentHead } from "./ShipmentHead";
import phn from '../../../../Asset/Rider/phn.png';
import msg from '../../../../Asset/Rider/msg.png';
import {  TData } from '../../../../data/shipment';



// interface Props {
//     details: {
//       sortedData: TData
//     }
//   }
const tableData = [
    {
        destination: "Ikeja-Ikorodu",
        fullname: "John Tae",
        order_status: "Assigned",
        order_id: "#110934A2",
        date: "12-20-2022",
        status: "In transit",
    }
]
const ShipmentRoute = ()=>{
    const [sortedData, setData] = useState<TData | null | undefined>(tableData)

    return(
        <>
            <DashboardLayout section="/shipment">
                <Box position="relative">
                    <Image src={images.maps} alt="maps" />
                    {sortedData.map(data=>(
                        <ShipmentHead item={data} phn={phn} msg={msg} />
                    ))}
                    
                    <Button 
                        position="absolute" 
                        bottom="5rem"
                        right="5rem"
                        bg="rgba(7, 5, 41, 0.9)" 
                        colorScheme="#fff"
                        onClick={()=>window.history.back()}>
                        Back
                    </Button>
                </Box>
            </DashboardLayout>
           
        </>
    );
}
export default ShipmentRoute;