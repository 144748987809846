import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Box,
    Image,
    Text,
    Button
}
from "@chakra-ui/react";
import { MdOutlineCancel } from "react-icons/md";
import * as images from "../../../images"
const CreatePayrollModal = ({ isOpen, onClose })=>{
    return(
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Box display="flex" flexDirection="column" p={2}>
                            <Box display="flex" justifyContent="space-between">
                                <Image src={images.LOGO} alt="sendrail" />
                                <Text cursor="pointer" onClick={onClose}><MdOutlineCancel /></Text>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={5}>
                                <Text as="b" color="#333344" fontSize="24px" fontFamily="sans-serif" mb={4}>Confirmation</Text>
                                <Text as="h4" color="#333344" fontSize="18px" fontFamily="sans-serif">Confirmation</Text>
                            </Box>
                            <Box p={3} display="flex" flexDirection="column" border="1px" borderColor="#A0A0AA" borderStyle="dashed" borderRadius="10px">
                                <Box display="flex" justifyContent="space-between" my={2}>
                                    <Text color="#01001B" fontSize="16px" fontWeight="bold">Courier's name</Text>
                                    <Text  color="#656566" fontSize="14px">Micheal sule</Text>
                                </Box>
                                <Box border="1px" borderColor="#A0A0AA" borderStyle="dashed"></Box>
                                <Box display="flex" justifyContent="space-between" my={2}>
                                    <Text color="#01001B" fontSize="16px" fontWeight="bold">Courier's name</Text>
                                    <Text  color="#656566" fontSize="14px">Micheal sule</Text>
                                </Box>
                            </Box>
                            <Button my={2} bg="rgba(7, 5, 41, 0.9)" borderRadius="8px" colorScheme="#fff">Confirm</Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
export default CreatePayrollModal;