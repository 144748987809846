import Img from '../Asset/profile-picture.png'
export const sampleRiders: {
    id: number
    name: string,
    email: string,
    Payroll: string,
    Income: string,
    Type: string,
    icons: string
}[] = [
        {
            id: 1,
            name: "John Tae",
            email: "tousie@gmail.com",
            Payroll: "Commission",
            Income: "#65,000",
            Type: "Motorcycle Rider",
            icons: Img
        },
        {
            id: 1,
            name: "John Tae",
            email: "tousie@gmail.com",
            Payroll: "Salary",
            Income: "#65,000",
            Type: "Motorcycle Rider",
            icons: Img
        },
        {
            id: 1,
            name: "John Tae",
            email: "tousie@gmail.com",
            Payroll: "Salary",
            Income: "#65,000",
            Type: "Motorcycle Rider",
            icons: Img
        },
        {
            id: 1,
            name: "John Tae",
            email: "tousie@gmail.com",
            Payroll: "Commission",
            Income: "#65,000",
            Type: "Motorcycle Rider",
            icons: Img
        }
        
    ]

export const tableHeadings: string[] = [
    "Name",
    "Email",
    "Payroll",
    "Income",
    "Type",
    "Action"
]

export const sampleHistory: {
    status: "Transaction Successful" | "Transaction Failed" | "Transaction Pending",
    date: string,
    amount: string
}[] = [
        {
            status: "Transaction Successful",
            date: "January 1, 8:00am",
            amount: "₦60,000.00"
        },
    ]

