import React from "react";
import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Box,
    Image,
    Button,


} from "@chakra-ui/react";
import * as images from "../../images";
import { BsTelephoneFill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { BsFillMicMuteFill } from "react-icons/bs";

const Call = ({ isOpen, onClose })=>{
    return(
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xs">
                <ModalOverlay />
                <ModalContent
                    bgPosition="center" 
                    bgRepeat="no-repeat" 
                    backgroundImage={`url${images.LOGO}`}>
                    <ModalBody 
                        display="flex" 
                        alignItems="center" 
                        justifyContent="center" 
                        flexDir="column" 
                        width="100%"
                        height="100%"
                        bgSize="contain"
                        mt="30px">
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" fontWeight="600">
                            <Image src={images.caller} boxSize="100px" alt="caller" />
                            <Box display="flex" flexDirection="column" alignContent="center" justifyContent="center">
                                    <Text as="h4" fontSize="25px">Ebenezer Tae</Text>
                                    <Text as="h4" fontSize="20px" pl={3}>Incoming call</Text>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" width="70%" mt="15px">
                            <Box display="flex" flexDirection="column">
                                <Button borderRadius="100%" width="40px" height="40px" color="#fff" bg="green" fontSize="35px"><FaTimes /></Button>
                                <Text mt="5px">Decline</Text>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Button borderRadius="100%" width="40px" height="40px" color="#fff" bg="red" fontSize="35px"><BsTelephoneFill /></Button>
                                <Text mt="5px">Answer</Text>
                            </Box>
                        </Box>
                        <Box display="flex" bg="red" width="100%" height="" borderRadius="3px" justifyContent="space-between" alignItems="center">
                            <Text width="40px" height="40px"><BsFillMicMuteFill /></Text>
                            <Text><BsTelephoneFill /></Text>
                            <Box><Image src={images.speaker} alt="images" /></Box>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
export default Call;

