import React from "react";
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
    Text,
    Box,
    useDisclosure
} from "@chakra-ui/react";
import { ImCancelCircle } from "react-icons/im";
import { FaAngleRight } from "react-icons/fa";
import { FaTimes } from 'react-icons/fa';
import EditParcel from "./EditParcel";


interface Props{
    isOpen?: boolean,
    onClose?: ()=> void,
    parcelNum?: number
}
const TotalParcel = ({ isOpen, onClose, parcelNum }: Props)=>{
    const { isOpen: isParcel, onOpen: onParcel, onClose: closeParcel } = useDisclosure();
    return(
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Flex 
                            alignItems="center" 
                            justifyContent="space-between" 
                            color="#656566" 
                            borderBottom="1px" 
                            borderColor="rgba(220, 219, 234, 0.40)"
                            p={5}>
                                <Text>Total parcels</Text>
                                <Text cursor="pointer" onClick={()=>onClose()}><ImCancelCircle /></Text>
                        </Flex>
                        {
                            Array(parcelNum).fill("parcel").map((num, i)=>(
                                <Box my={8}  display="flex" alignItems="center" justifyContent="space-between">
                                    <Box
                                        p={4}
                                        display="flex" 
                                        alignItems="center" 
                                        justifyContent="space-between"
                                        bg="#fff" 
                                        borderRadius="20px"
                                        border="2px"
                                        borderColor="rgba(6, 0, 137, 0.10)" 
                                        width="350px" 
                                        color="#ABA7A7"
                                        height="55px"
                                        cursor="pointer"
                                        onClick={()=> onParcel()}>
                                            <Text>{num+i}</Text>
                                            <Text><FaAngleRight /></Text>
                                    </Box>
                                    <Box cursor="pointer" color="#ABA7A7"><FaTimes /></Box>
                                </Box>
                                

                            ))
                        }
                        <EditParcel isOpen={isParcel} onClose={closeParcel} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
export default TotalParcel;