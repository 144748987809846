import React from "react";
import { Modal, ModalOverlay, ModalBody, ModalContent, Box, Image, Heading, Text, Button, useDisclosure } from "@chakra-ui/react";
import box from "../../../../Asset/box.png";
import ConfirmCancelOrder from "./ConfirmCancelOrder";
const CancelOrder = ({ isOpen, onClose })=>{
    const { isOpen: isConfirm, onOpen: openConfirm, onClose: closeConfirm } = useDisclosure();
    return(
        <>
            <Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInBottom'>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                       <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Image src={box} boxSize="56px" alt="sendrail" mt={3} />
                            <Heading as="h5" color="#000" fontFamily="sans-serif" fontSize="23px" my={5}>Order Alert</Heading>
                            <Text color="#595956" fontWeight="500px" fontSize="20px">Are you sure you want to cancel </Text>
                            <Text color="#595956" fontWeight="500px" fontSize="20px">this order.</Text>
                            <Box  my={6} width="50%" display="flex" justifyContent="space-between" mt={5}>
                                <Button colorScheme="#333344" variant="outline" onClick={onClose}>Cancel</Button>
                                <Button 
                                    bg="rgba(7, 5, 41, 0.9)"
                                    colorScheme="#fff"
                                    onClick={()=>{
                                        openConfirm()
                                        onClose()
                                    }}>Yes</Button>
                            </Box>
                        </Box> 
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ConfirmCancelOrder isOpen={isConfirm} onClose={closeConfirm}  />
        </>
    );
}
export default CancelOrder;