import React from "react";
import {
    Text,
    Flex,
    Button,
    Image,
    Box
} from "@chakra-ui/react";
import mac from '../../../Asset/Rider/mac.png'
const Parcel = ({ parcel, setParcel })=>{
    return(
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
              <Flex
                  width="28.875rem"
                  height="12rem"
                  flexDir="column"
                  justifyContent="space-between"
                  borderRadius="20px"
                  padding="1rem"
                  my="1rem"
                  border=" 1px solid rgba(6, 0, 137, 0.1)">
                  <Text flexShrink="0" color="#000" fontWeight="500" fontSize="18px" lineHeight="22.7px">
                    Parcel details
                  </Text>
                  <Flex justifyContent="space-between">
                    <Flex flexDir="column" height="8rem" justifyContent="space-between">
                      <Text
                        flexShrink="0"
                        color="text.100"
                        fontWeight="450"
                        fontSize="16px"
                        lineHeight="20.24px">
                        No Of Parcel
                      </Text>
                      <Text
                        flexShrink="0"
                        color="text.100"
                        fontWeight="450"
                        fontSize="16px"
                        lineHeight="20.24px">
                        Parcel
                      </Text>
                      <Text
                        flexShrink="0"
                        color="text.100"
                        fontWeight="450"
                        fontSize="16px"
                        lineHeight="20.24px">
                        Category
                      </Text>
                    </Flex>
                    <Flex flexDir="column" height="8rem" justifyContent="space-between">
                      {' '}
                      <Text
                        flexShrink="0"
                        color="text.50"
                        fontWeight="450"
                        fontSize="16px"
                        lineHeight="20.24px">
                        4
                      </Text>
                      <Text
                        flexShrink="0"
                        color="text.50"
                        fontWeight="450"
                        fontSize="16px"
                        lineHeight="20.24px">
                        Mac Book Pro
                      </Text>
                      <Text
                        flexShrink="0"
                        color="text.50"
                        fontWeight="450"
                        fontSize="16px"
                        lineHeight="20.24px">
                        Computer Accessories
                      </Text>
                    </Flex>
                    <Flex alignSelf="center" flexDirection="column" justifyContent="space-between">
                      <Image src={mac} alt="computer" objectFit="cover" width="81px" height="88px" />
                          <Button
                            flexShrink="0"
                            my="1rem"
                            fontWeight="500"
                            fontSize="18px"
                            lineHeight="22.77px"
                            border="1px"
                            borderColor="black"
                            bg="#fff"
                            onClick={()=> setParcel(!parcel)}>
                            Back
                          </Button>
                    </Flex>
                  </Flex>
                </Flex>
          </Box>
        </>
    );
}
export default Parcel;